import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { map, catchError } from 'rxjs/operators';
import { Helpers } from '../helpers';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileJacketService {
  url = environment.host;
  endPoints = {
    createFolder: 'api/FileJacket/CreateFolder'
  }

constructor(private _http: HttpClient, private router: Router) { }

createNewFolder(data) {
  let headers = new HttpHeaders()
  .set("Content-Type", "application/x-www-form-urlencoded")
  .set("Authorization", User.getCurrentUser().token);
  let requestBody = this.jsonToURLEncoded(data);
  let link = `${this.url}${this.endPoints.createFolder}`;
  // return this._http.post(`${this.url}${this.endPoints.createFolder}`, requestBody, {headers});
  return this._http.post(link, requestBody, {headers, observe: 'response'}).pipe(
    map(response => {
     return response;
    }),
    catchError((error: HttpErrorResponse) => {
     if(error.status == 401){
         localStorage.removeItem(Helpers.STORAGE_TAG);
         this.router.navigate(['/login']);
     }
     return Observable.throw(error);
    })
)
}

private jsonToURLEncoded(jsonString){
  return Object.keys(jsonString).map(function(key){
      return encodeURIComponent(key) + '=' + encodeURIComponent(jsonString[key]);
  }).join('&');
}

}
