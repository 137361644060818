import { Component, Input, HostListener, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-files-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() placeholder;
  @Input() id;
  @Input() UploadedFilenames;
  onChange: Function;
  public file: FileList | null = null;
  public files: Array<any> = [];

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const file = event;
    this.file = file;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.file.length; index++) {
      const element = this.file[index];
      this.files.push(element);
    }
    this.onChange(this.files);
  }

  constructor( private host: ElementRef<HTMLInputElement> ) {
  }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
    this.files = [];
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {
  }


}
