import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-htmml-viewer',
  templateUrl: './htmml-viewer.component.html',
  styleUrls: ['./htmml-viewer.component.scss']
})
export class HtmmlViewerComponent implements OnInit {

  @Input() html: any;

  constructor(private sanitizer: DomSanitizer) { 
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.html);
  }

  ngOnInit() {
  }

}
