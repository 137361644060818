import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NetworkService } from './../network/_services/network';
import { User } from 'src/app/models/user';
import { DashboardLayoutComponent } from './../dashboard-layout/dashboard-layout.component';
import { Component, OnInit } from '@angular/core';
import { AlertHelper } from '../helpers/alert-helper';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  parent: DashboardLayoutComponent;
  loggedUser: User;

  frm_err: boolean = false;
  frm_err_text: string = "";
  show_profile_async: boolean = false;
  alertHelper: AlertHelper;
  fetch_details_async: boolean = true;

  usr_data = {Email: "", FirstName: "", LastName: "", MiddleName: "", PhoneNo: "", PhoneNo2: "", PersonalEmail: "", TitleId: null, StaffId: null};


  constructor(private network: NetworkService, private router: Router, private dialog: MatDialog) {
    this.loggedUser = User.getCurrentUser();
    this.alertHelper = new AlertHelper(dialog);
   }

  ngOnInit() {
    this.network.sendRequest({}, NetworkService.FETCH_DETAILS).subscribe((res)=>{
      this.fetch_details_async = false;
      if(!res['error']){
        this.usr_data = JSON.parse(res['response']);

      }else{
      }
    }, (error)=>{
      this.fetch_details_async = false;
      this.alertHelper.showErrorAlert();
    })
  }
  goHome(){
    this.router.navigate(['/']);
  }

  saveProfile(){
    this.frm_err = false;
    var errorInProfile = this.validateProfile();
    if(typeof errorInProfile == "string"){
      this.frm_err = true;
      this.frm_err_text = errorInProfile;
      this.alertHelper.showOkAlert("Error", this.frm_err_text);
    }else{
      //Submit Form
      console.log(this.usr_data);
      this.show_profile_async = true;
      this.network.sendRequest(this.usr_data, NetworkService.UPDATE_PROFILE).subscribe((result) =>{
        this.show_profile_async = false;
        if(!result['error']){
          var user: User = JSON.parse( result['response']);
          User.updateCurrentUser(user, true);
          this.alertHelper.showOkAlert("Success", "Profile Updated Successfully", ()=>{this.router.navigate(['/dashboard'])}, 4000);
        }else{
          this.alertHelper.showOkAlert("Profile Update Error", result['response']);
        }

      }, (error) =>{
        this.show_profile_async = false;
        this.alertHelper.showErrorAlert();
      });
    }
  }

  validateProfile(): string | boolean{
    if(this.usr_data.FirstName.trim() == ""){
      return "Please enter your first name";
    }else if(this.usr_data.MiddleName.trim() == ""){
      return "Please enter your middle name";
    }else if(this.usr_data.LastName.trim() == ""){
      return "Please enter your last name";
    }else if(this.usr_data.PhoneNo.trim() == ""){
      return "Please enter your mobile number";
    }else if(this.usr_data.PersonalEmail.trim() == ""){
      return "Please enter personal email";
    }else if(this.usr_data.StaffId == null){
      return "Please select your staff ID";
    }else if(this.usr_data.TitleId.trim() == ""){
      return "Please enter your title";
    }else if(this.usr_data.Email.trim() == ""){
      return "Please enter your email";
    }
    return false;
  }

}
