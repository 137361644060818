import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material';
import { AlertHelper } from './../helpers/alert-helper';
import { DashboardLayoutComponent } from './../dashboard-layout/dashboard-layout.component';
import { NetworkService } from './../network/_services/network';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { SubmissionComment, TreatmentStatus } from '../treat-file/treat-file.component';
import moment from 'moment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-internal-document',
  templateUrl: './internal-document.component.html',
  styleUrls: ['./internal-document.component.scss']
})
export class InternalDocumentComponent implements OnInit {

  loggedUser: User;
  parent: DashboardLayoutComponent;
  document_ref: string = "";
  document_type: string = "";
  document_info: DocumentInfo = new DocumentInfo();
  actionInprogress = false;
  actionCommentShow = false;
  tokenApprovalShow = false;
  resendingMemoToken = false;
  timeOutToResendToken = false;
  timeLeft = 0;
  enteredTokenForApproval = '';
  actionEvent = {
    displayName: '',
    value: 0
  };
  actionCommentMessage = '';
  memoTreatmentStatus = MemoTreatmentStatus;
  selectedNewMemoRecipient = null;

  files_async: boolean = false;
  comments_async: boolean = false;
  showRetryOnError: boolean = false;
  errorPage: boolean = false;
  errorPage_text: string = "";

  route_dialog= {route: false, routeTitle:  "", routeType: "", routeAsync: false};
  route_message: string = "";

  toggle_users_dropdown: boolean = true;
  selected_route_sub_user: {id: string, name: string, level: number, position: string};
  sel_department: {id: string, name: string};
  view_supporting_docs: boolean = false;
  view_supporting_docs_async: boolean = false;

  config = {
    displayKey:"name", //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: 10, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  reject_submission: boolean = false;
  reject_submission_err: boolean = false;
  reject_submission_err_text: string = "";

  approve_disapprove: {title: string, async: boolean, error: boolean, errText: string, show: boolean} = {title: "", async: false, error: false, errText: "", show: false};
  approve_disapprove_message: string = "";

  departments_load_async: boolean = false;
  department_users_load_async: boolean = false;

  submission_departments: {id: string, name: string}[] = new Array();
  department_users: {id: string, name: string, level: number, position: string}[] = new Array();

  alertHelper: AlertHelper;

  doc_types: Map<string, number> = new Map();
  allAvailableFolders: {id: number, folderId: string, folderName: string}[] = [];
  selectedMemoFolder: {id: number, folderId: string, folderName: string};
  createFolderForm: FormGroup;
  creatingFolderInProgress = false;
  addingMemoToFolder = false;
  formSubmitted = false;
  sub: Subscription = new Subscription();

  recipientsSearchValue: string = "";
  mrecipients: Array<{name: string, color: string, fname: string, lname: string, email: string, position: string}> = new Array();

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router, private network: NetworkService, private dialog: MatDialog, private cd: ChangeDetectorRef) {
    this.doc_types.set("memo", InternalDocumentType.Memo);
    this.doc_types.set("awaiting-memo", InternalDocumentType.Memo);
    this.doc_types.set("minute", InternalDocumentType.Minute);
    this.doc_types.set("circular", InternalDocumentType.Circular);
    this.route.params.subscribe((params) => {
      //console.log(params)
      this.document_ref = params['ref'];
      this.document_type = params['type'];
    })
    
    if(!this.document_ref || !this.document_type || this.doc_types.get(this.document_type) == null){
      this.router.navigate(['/']);
    }
    this.alertHelper = new AlertHelper(dialog);
    this.loggedUser = User.getCurrentUser();
    
   }

  ngOnInit() {
    this.loadDocumentDetails();
    this.initCreateFolderForm();
  }

  fetchAllExisitingFoldersForMemo() {
    this.sub.add(
      this.network.sendFileJacketRequest({}, NetworkService.GET_ALL_FOLDERS).subscribe({
        next: rez => {
          this.allAvailableFolders = JSON.parse(rez['response']);
        },
        error: e => {
          this.fetchAllExisitingFoldersForMemo();
        }
      })
    );
  }

  initCreateFolderForm() {
    this.createFolderForm = this.fb.group({
      FolderId: ['', Validators.required],
      FolderName: ['', Validators.required]
    });
  }

  validateCreateFolderForm() {
    this.formSubmitted = true;
    if (this.createFolderForm.valid) {
      const payload = {
        FolderId: this.createFolderForm.get('FolderId').value.toUpperCase(),
        FolderName: this.createFolderForm.get('FolderName').value.toUpperCase()
      };
      this.creatingFolderInProgress = true;
      this.sub.add(
        this.network.sendFileJacketRequest(payload, NetworkService.CREATE_NEW_FOLDER).subscribe({
          next: response => {
            this.creatingFolderInProgress = false;
            this.formSubmitted = false;
            this.allAvailableFolders = [...this.allAvailableFolders, {
              id: response['Id'],
              folderId: response['FolderId'],
              folderName: response['FolderName']
            }];
            this.initCreateFolderForm();
            this.alertHelper.showOkAlert('Success', 'Folder created and added to the list of available folders');
          },
          error: e => {
            this.creatingFolderInProgress = false;
            this.alertHelper.showErrorAlert();
          }
        })
      );
    } else {
      return this.alertHelper.showOkAlert('Error', `You've got some invalid entries, please double check` );
    }
  }

  addNewMemoToFolder() {
    if (this.selectedMemoFolder === null) {
      return this.alertHelper.showOkAlert('Error', 'Please select a folder');
    } else {
      const payload = {
        folderId: this.selectedMemoFolder.id,
        memoRefNo: this.document_ref
      }
      this.addingMemoToFolder = true;
      this.sub.add(
        this.network.sendRequest(payload, NetworkService.ADD_MEMO_TO_FOLDER).subscribe({
          next: rez => {
            if (!rez['error']) {
              this.addingMemoToFolder = false;
              this.alertHelper.showOkAlert('Success', 'Memo successfully added to the specified folder.', ()=> {
                this.parent.reloadDashBoardVars();
                this.router.navigate(['dashboard']);
              });
            }
          },
          error: e => {
            this.addingMemoToFolder = false;
            this.alertHelper.showErrorAlert();
          }
        })
      );
    }
  }
  
  closeSupportingDocs() {
    this.view_supporting_docs = false;
    this.parent.showScroll();
  }
  currentSupportDocs = 0;
  prevCommentDoc() {
    if (this.currentSupportDocs > 0) {
      this.currentSupportDocs -= 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }
  nextCommentDoc() {
    if (this.currentSupportDocs < this.document_info.supportingDocs.length - 1) {
      this.currentSupportDocs += 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }

  showSupportingDocs() {
      this.view_supporting_docs = true;
      this.view_supporting_docs_async = true;
      this.parent.hideScroll();
  }
  closeCommentFiles() {
    this.view_supporting_docs = false;
    this.parent.showScroll();
  }
  commFilesLoaded(event) {
    if (this.view_supporting_docs_async) {
      this.view_supporting_docs_async = false;
    }
  }

  loadDocumentDetails(){
    this.files_async = true;
    this.comments_async = true;
    this.showRetryOnError = false;
    this.errorPage = false;
    this.network.sendRequest({refNo: this.document_ref, type: this.doc_types.get(this.document_type)}, NetworkService.GET_DOC_DETAILS).subscribe((res)=>{
      this.files_async = false
      this.comments_async = false;
      if(!res['error']){
        const result = JSON.parse(res['response']);
        this.document_info = result;
        this.document_info.reviwersList = JSON.parse(result.reviwersList);
        console.log(this.document_info);
        if(this.document_type == 'minute'){
          this.mrecipients = this.document_info.mrecipients;
        } else if(this.document_type == 'memo'){
          this.mrecipients = this.document_info.mrecipients;
        }
        this.fetchAllExisitingFoldersForMemo();
      }else{
        this.errorPage_text = res['response'];
        this.errorPage = true;
      }
    }, (error)=>{
      this.files_async = false
      this.comments_async = true;
      this.showRetryOnError = true;
      this.errorPage_text = "Sorry, an unexpected error occured.";
      this.errorPage = true;
    })
  }

  loadDepartments(){
    this.refreshDepartmentUsers();
    var _team = "0"
    if(this.submission_departments.length <= 0){
      this.departments_load_async = true;
      this.network.sendRequest({ref: this.document_ref, team: _team}, NetworkService.GET_DEPARTMENTS).subscribe((res)=>{
        this.departments_load_async = false
        if(!res['error']){
          var deps = JSON.parse(res['response']);
          this.submission_departments = deps;
          
        }
      }, (error)=>{
        this.departments_load_async = false;
      })
    }
  }
  refreshDepartmentUsers(){
    this.toggle_users_dropdown = false;
    this.selected_route_sub_user = null;
    this.department_users = new Array();
    setTimeout(() => {
      this.toggle_users_dropdown = true;
    }, 1);
  }

  departmentsChange(event){
    this.refreshDepartmentUsers();
    this.sel_department = event.value;
    if(event.value){
      this.department_users_load_async = true;
      this.network.sendRequest({ref: this.document_ref, did: event.value.id}, NetworkService.GET_DEPARTMENT_USERS_PLAIN).subscribe((res) => {
        this.department_users_load_async = false
        if(!res['error']){
          var usrs = JSON.parse(res['response']);
          this.department_users = usrs;
        }
      }, (error) => {
        this.department_users_load_async = false
      });
    }
  }

  openApproveDocument(){
    this.approve_disapprove = {title: "Approve", async: false, error: false, errText: "", show: true};
  }
  openDisApproveDocument(){
    this.approve_disapprove = {title: "Disapprove", async: false, error: false, errText: "", show: true};
  }

  confirmApproveDisapprove(){
    this.alertHelper.showYesNoAlert("Confirm", "Are you sure you want to <b>" + this.approve_disapprove.title + "</b> this document. <br/> This action cannot be undone.", () => {this.approveDisapproveDocument()})
  }

  approveDisapproveDocument(){
    var isApproved = false
    if(this.approve_disapprove.title == "Approve"){
      isApproved = true;
    }else if(this.approve_disapprove.title == "Disapprove"){
      isApproved = false;
    }else{
      return;
    }
    var url = "";
    var data = {};
    switch (this.document_type) {
      case 'memo':
        data['MemoRefNo'] = this.document_ref;
        data['IsApproved'] = isApproved;
        data['Message'] = this.approve_disapprove_message;
        url = NetworkService.MEMO_TREAT;
        break;
      case 'circular':
        data['RefNo'] = this.document_ref;
        data['IsApproved'] = isApproved;
        data['Message'] = this.approve_disapprove_message;
        url = NetworkService.CIRCULAR_TREAT;
        break;
      default:
        return;
    }
    this.approve_disapprove.async = true;
    this.network.sendRequest(data, url).subscribe((res)=>{
      this.approve_disapprove.async = false;
      if(!res['error']){
        this.approve_disapprove.show = false;
        this.approve_disapprove_message = "";
        this.alertHelper.showOkAlert("Success", res['response']);
      }else{
        this.alertHelper.showOkAlert("Error", res['response']);
      }
    }, (error)=>{
      this.approve_disapprove.async = false;
      this.alertHelper.showErrorAlert();
    })
  }

  submitRoute(){
    if(this.sel_department == null){
      this.alertHelper.showOkAlert("Error", "Please select user's department")
    }else if(this.selected_route_sub_user == null){
      this.alertHelper.showOkAlert("Error", "Please select a user")
    }else if(this.route_message.trim() == ""){
      this.alertHelper.showOkAlert("Error", "Please enter a message for user")
    }else{
      var url: string = "";
      var data = {};
      data['MessageBody'] = this.route_message;
      switch (this.document_type) {
        case 'memo':
          data['MemoRefNo'] = this.document_ref;
          data['RoutedTo'] = this.selected_route_sub_user.id;
          data['Comment'] = this.route_message;
          url = NetworkService.ROUTE_MEMO;
          break;
        case 'minute':
          data['MinuteRefNo'] = this.document_ref;
          data['RouteeId'] = this.selected_route_sub_user.id;
          data['MessageBody'] = this.route_message;
          url = NetworkService.ROUTE_MINUTE;
          break;
        case 'circular':
          data['RefNo'] = this.document_ref;
          data['To'] = this.selected_route_sub_user.id;
          data['Message'] = this.route_message;
          url = NetworkService.ROUTE_CIRCULAR;
          break;
        default:
          return;
      }
      this.route_dialog.routeAsync = true;
      this.network.sendRequest(data, url).subscribe((res)=>{
        this.route_dialog.routeAsync = false;
        if(!res['error']){
          this.route_dialog.route = false;
          var comment: SubmissionComment = new SubmissionComment();
          comment.sender = this.loggedUser.FirstName + " " + this.loggedUser.LastName + " (" + this.loggedUser.Position + ")";
          comment.recipient = this.selected_route_sub_user.name;
          var cdate = moment().toString();
          comment.comment_date = cdate
          comment.comment = this.route_message;
          comment.sender_color = this.loggedUser.UserColour;
          this.route_message = "";
          this.document_info.comments.unshift(comment);
          this.alertHelper.showOkAlert("Success", res['response'], () => {
            this.router.navigate(['dashboard']);
          });
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.route_dialog.routeAsync = false;
        this.alertHelper.showErrorAlert();
      })
    }
    
  }

  docLoaded(event){

  }

  routeDocument(){
    this.route_message = "";
    this.route_dialog = {route: true, routeTitle: "Route this document", routeType: "sub", routeAsync: false}
    this.loadDepartments();
  }

  getTreatmentStatus(status: TreatmentStatus){
    switch (status) {
      case TreatmentStatus.Pending:
        return "Pending";
        break;
      case TreatmentStatus.Rejected:
        return "Disapproved";
        break;
      case TreatmentStatus.Treated:
        return "Approved";
        break;
      case TreatmentStatus.Untreated:
        return "Untreated";
        break;
      case TreatmentStatus.AwaitingApproval:
        return "Pending";
        break;
      case TreatmentStatus.Approved:
        return "Approved";
        break;
      case TreatmentStatus.Disapproved:
        return "Disapproved";
        break;
      default:
        break;
    }
  }

  searchRecipients(){
    if(this.recipientsSearchValue.trim() != ""){
      this.mrecipients = this.document_info.mrecipients.filter((c) =>{
        return c.fname.toLowerCase().includes(this.recipientsSearchValue.toLowerCase()) || c.lname.toLowerCase().includes(this.recipientsSearchValue.toLowerCase());
      });
    }else{
      this.mrecipients = this.document_info.mrecipients;
    }
  }

  clearSearchRecipients(){
    this.recipientsSearchValue = "";
    this.mrecipients = this.document_info.mrecipients;
  }

  
  saveForPrinting()
  {
    html2canvas(document.querySelector('#entire-memo-wrapper')).then(canvas => {

      const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

      const imgData  = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
      pdf.save('');
    });
  }

  newCommentContent: string = '';
  sendingCommentAsync = false;
  approvingMemo = false;
  treatingMemo = false;
  rejectingMemo = false;
  sendComment() {
    if (this.newCommentContent === '') {
      this.alertHelper.showOkAlert('Error', 'You need to enter a comment first');
      return;
    } else {
      console.log(this.newCommentContent);
      const payload = {
        RefNo: this.document_info.ref,
        Comment: this.newCommentContent
      };
      this.sendingCommentAsync = true;
      this.network.sendRequest(payload, 'comment-memo').subscribe({
        next: result => {
          this.sendingCommentAsync = false;
          this.newCommentContent = '';
          const response = JSON.parse(result['response']);
          const comm: SubmissionComment = {
            comment: response.Comment,
            comment_date: response.DateCreated,
            comment_id: response.Id,
            sender: `${this.loggedUser.FirstName} ${this.loggedUser.LastName} - ${this.loggedUser.Position}`,
            recipient: '',
            sender_color: this.loggedUser.UserColour,
            files: []
          };
          this.document_info.comments.push(comm);
        },
        error: e => {
          this.sendingCommentAsync = false;
          this.alertHelper.showErrorAlert();
        }
      });
    }
  }

  // returnMemoToSender() {
  //   this.alertHelper.showYesNoAlert('Confirm Action', 'Are you sure you want to return this memo back to the initiator?', () => {
  //     const payload = {
  //       MemoRefNo: this.document_info.ref,
  //       Status: status
  //     };
  //     this.treatingMemo = true;
  //     this.network.sendRequest(payload, 'review-memo').subscribe({
  //       next: response => {
  //         this.treatingMemo = false;
  //         this.alertHelper.showOkAlert('Success', 'Memo approved and sent to the recipient!', () => {
  //           this.parent.reloadDashBoardVars();
  //           this.router.navigate(['/dashboard']);
  //         });
  //       },
  //       error: e => {
  //         this.treatingMemo = false;
  //         this.alertHelper.showErrorAlert();
  //       }
  //     });
  //   });
  // }

  reviewMemo(status: number) {
    if (this.document_info.status === this.memoTreatmentStatus.Updated) {
      // Through Approval
      this.alertHelper.showYesNoAlert('Confirm Approval', 'Are you sure you want to approve this memo?', () => {
        const payload = {
          MemoRefNo: this.document_info.ref,
          Status: status
        };
        this.treatingMemo = true;
        this.network.sendRequest(payload, NetworkService.REVIEW_MEMO_ONLY).subscribe({
          next: response => {
            this.treatingMemo = false;
            this.alertHelper.showOkAlert('Success', 'Memo approved and sent to the recipient!', () => {
              this.parent.reloadDashBoardVars();
              this.router.navigate(['/dashboard']);
            });
          },
          error: e => {
            this.treatingMemo = false;
            this.alertHelper.showErrorAlert();
          }
        });
      });
    } else if (this.document_info.status === this.memoTreatmentStatus.PendingTOApproval) {
      if (this.document_info.paymentMemo) {
        this.alertHelper.showYesNoAlert('Action Required',
        'This is a payment memo and as such requires a token to be generated for approval! Generate token now?', () => {
          const payload = {memoRefNo: this.document_info.ref};
          this.treatingMemo = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.GENERATE_MEMO_APPROVAL_TOKEN).subscribe(
              {
                next: response => {
                  this.treatingMemo = false;
                  this.startTimeOutFunction();
                  this.tokenApprovalShow = true;
                },
                error: e => {
                  this.treatingMemo = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
        })
      } else {
        this.completeMemoFinalApprovalNoToken();
      }
    }
  }

  confirmTokenAndApproveMemo() {
    if (this.enteredTokenForApproval === '') {
      this.alertHelper.showOkAlert('Error', 'Please enter the token sent to your email!');
    } else {
      const payload = {
        memoRefNo: this.document_info.ref,
        token: this.enteredTokenForApproval
      };
      this.actionInprogress = true;
      this.sub.add(
        this.network.sendRequest(payload, NetworkService.FINAL_MEMO_APPROVER_WITH_TOKEN).subscribe({
          next: response => {
            if (!response['error']) {
              this.actionInprogress = false;
              this.tokenApprovalShow = false;
              this.alertHelper.showOkAlert('Success', 'Memo approved successfully, you will now be returned to the dashboard!', () => {
                this.parent.reloadDashBoardVars();
                this.router.navigate(['/dashboard']);
              });
            } else {
              this.actionInprogress = false;
              this.alertHelper.showOkAlert('Error', response['response']);
            }
          },
          error: e => {
            this.actionInprogress = false;
            this.alertHelper.showErrorAlert();
          }
        })
      );
    }
  }

  reSendMemoApprovalToken() {
    const payload = {memoRefNo: this.document_info.ref};
    this.resendingMemoToken = true;
    this.sub.add(
      this.network.sendRequest(payload, NetworkService.GENERATE_MEMO_APPROVAL_TOKEN).subscribe(
        {
          next: response => {
            this.resendingMemoToken = false;
            this.timeOutToResendToken = false;
            this.startTimeOutFunction();
          },
          error: e => {
            this.resendingMemoToken = false;
            this.timeOutToResendToken = true;
            this.alertHelper.showErrorAlert();
          }
        }
      )
    );
  }

  startTimeOutFunction() {
    this.timeLeft = 30;
    const timerCheck = setInterval(() => {
      if (this.timeLeft === 0) {
        this.timeOutToResendToken = true;
      } else {
        this.timeLeft--;
      }
    }, 1000);
  }

  completeMemoFinalApprovalNoToken() {
    this.alertHelper.showYesNoAlert('Confirm Approval', 'Are you sure you want to approve this memo?', () => {
      const payload = {
        MemoRefNo: this.document_info.ref,
        Status: status
      };
      this.treatingMemo = true;
      this.network.sendRequest(payload, NetworkService.FINAL_MEMO_APPROVER_NO_TOKEN).subscribe({
        next: response => {
          this.treatingMemo = false;
          this.alertHelper.showOkAlert('Success', 'Memo Approved Successfully!', () => {
            this.parent.reloadDashBoardVars();
            this.router.navigate(['/dashboard']);
          });
        },
        error: e => {
          this.treatingMemo = false;
          this.alertHelper.showErrorAlert();
        }
      });
    });
  }

  performActionOnDoc(type) {
    switch (type) {
      case 1: {
        this.actionEvent = {
          displayName: 'Return To Sender',
          value: 5
        }
        this.actionCommentShow = true;
        break;
      }
      case 2: {
        this.actionEvent = {
          displayName: 'Completed',
          value: 1
        }
        this.actionCommentShow = true;
        break;
      }
      case 3: {
        this.actionEvent = {
          displayName: 'Put Away',
          value: 2
        };
        this.actionCommentShow = true;
        break;
      }
      case 4: {
        this.actionEvent = {
          displayName: 'Keep In View',
          value: 3
        };
        this.actionCommentShow = true;
        break;
      }
      case 5: {
        this.actionEvent = {
          displayName: 'Additional Information Required',
          value: 4
        };
        this.actionCommentShow = true;
        break;
      }
      case 6: {
        this.loadDepartments();
        this.actionEvent = {
          displayName: 'Review Document',
          value: 6
        };
        this.actionCommentShow = true;
        break;
      }
    }
  }

  closeDocAction() {
    this.actionCommentShow = false;
    this.actionEvent = {
      displayName: '',
      value: 0
    };
    this.actionCommentMessage = '';
  }

  closeTokenApproval() {
    this.tokenApprovalShow = false;
    this.enteredTokenForApproval = '';
  }

  changeStaffFileStatus(status) {
    console.log(status);
    console.log(this.selectedNewMemoRecipient);
    if (status === 6 && this.selectedNewMemoRecipient.length === 0) {
      return this.alertHelper.showOkAlert('Error', `Please select a new recipient or use the 'Approve' button`);
    }
    if (this.actionCommentMessage === '') {
      return this.alertHelper.showOkAlert('Error', 'You need to enter a comment to proceed!');
    }
    const payload = {
      memoRefNo: this.document_info.ref,
      status,
      comment: this.actionCommentMessage
    };
    const RTSpayload = {
      MemoRefNo: this.document_info.ref,
      Comment: this.actionCommentMessage
    };
    switch (status) {
      case 6:
        {
          const RACRpayload = {
            MemoRefNo: this.document_info.ref,
            Staus: 2,
            newToId: this.selectedNewMemoRecipient.id,
            comment: this.actionCommentMessage
          };
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(RACRpayload, NetworkService.REVIEW_MEMO_AND_CHANGE_RECIPIENTS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `You have successfully approved the memo and also changed the final approver.`, () => {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  });
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 5:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(RTSpayload, NetworkService.RETURN_MEMO_TO_SENDER).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `You have successfully returned the memo back to the sender.`, () => {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 4:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_MEMO_FILE_STATUS).subscribe(
            {
              next: result => {
                this.actionInprogress = false;
                this.alertHelper.showOkAlert('Success', `Memo returned to the registry requiring additional information`, () => {
                  this.parent.reloadDashBoardVars();
                  this.router.navigate(['dashboard']);
                })
              },
              error: e => {
                this.actionInprogress = false;
                this.alertHelper.showErrorAlert();
              }
            }
            )
          );
          break;
        }
      case 3:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_MEMO_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Memo returned to the registry with the instruction to 'Keep In View'`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 2:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_MEMO_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Memo returned to the registry with the instruction to Put Away`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 1:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_MEMO_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Memo returned to the registry as 'Completed'`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      default:
        break;
    }
  }
  returnToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  getMemoTreatmentStatus(status: MemoTreatmentStatus) {
    switch (status) {
      case MemoTreatmentStatus.Pending:
        return 'Pending';
        break;
      case MemoTreatmentStatus.Updated:
        return 'Awaiting Review';
        break;
      case MemoTreatmentStatus.PendingTOApproval:
        return 'Awaiting Final Approval';
        break;
      case MemoTreatmentStatus.Treated:
        return 'Approved';
        break;
      default:
        break;
    }
  }
}

class DocumentInfo{
  ref: string = ""; 
  recipients: string = ""; 
  sdate: string = ""; 
  sender_email: string = "";
  published: boolean = false;
  hasConfirmedToken: boolean = false;
  paymentMemo: boolean = false;
  subject: string = ""; 
  viewerType: InternalDocViewerType = null; 
  file: {fid: string, file_name: string, url: string};
  fileurl: string = "";
  comments: Array<SubmissionComment> = new Array();
  mcomments: Array<SubmissionComment> = new Array();
  level: number;
  initiator: string = "";
  recipient: string = "";
  message: string = "";
  status: number;
  treatment_status: number;
  finalMessage: string = "";
  content: string = "";
  approvedBy: string = "";
  documentType: number;
  supportingDocs: Array<{fileName: string, filePath: string}> = new Array();
  mrecipients?: Array<{name: string, color: string, fname: string, lname: string, email: string, position: string}> = new Array();
  reviwersList?: Array<{name: string, status: number, fname: string, lname: string, email: string, position: string}> = new Array();
}
export enum InternalDocumentType{
  Memo = 1,
  Minute = 2,
  Circular = 3
}
enum InternalDocViewerType{
  Owner = 'Owner',
  Routee = 'Routee',
  Review = 'Review',
  Recipient = 'Recipient',
  CopiedRecipient = 'Copied Recipient',
  ViewOnly = 'View Only',
}

enum MemoTreatmentStatus
{
    Pending = 8,
    Treated = 9,
    Rejected = 10,
    Updated = 7,
    PendingTOApproval = 6,
    Complete = 1,
    PutAway = 2,
    KIV = 3,
    AdditionalInfoRequired = 4
}