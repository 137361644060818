import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NetworkService } from './../network/_services/network';
import { DashboardLayoutComponent } from './../dashboard-layout/dashboard-layout.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PageSettingsModel, IFilter, FilterSettingsModel, CommandModel, CommandClickEventArgs } from '@syncfusion/ej2-grids';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import moment from "moment";
import { AlertHelper } from '../helpers/alert-helper';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { InternalDocumentType } from '../internal-document/internal-document.component';
import { TreatmentStatus } from '../treat-file/treat-file.component';

@Component({
  selector: 'app-memos',
  templateUrl: './memos.component.html',
  styleUrls: ['./memos.component.scss']
})
export class MemosComponent implements OnInit {

  @ViewChild('memogrid', {static: false}) public memo_grid: GridComponent;
  @ViewChild('minutegrid', {static: false}) public minute_grid: GridComponent;
  @ViewChild('circulargrid', {static: false}) public circular_grid: GridComponent;
  
  view_memo_file: boolean = false;
  view_memo_file_async: boolean = false;
  memos_loader: boolean = false;
  memos: Array<InternalDocument> = new Array();

  view_minute_file: boolean = false;
  view_minute_file_async: boolean = false;
  minutes_loader: boolean = false;
  minutes: Array<InternalDocument> = new Array();

  view_circular_file: boolean = false;
  view_circular_file_async: boolean = false;
  circulars_loader: boolean = false;
  circulars: Array<InternalDocument> = new Array();


  public pageSettings: PageSettingsModel;
  public toolbar: string[];

  parent: DashboardLayoutComponent;

  selected_memo: InternalDocument = new InternalDocument();
  selected_minute: InternalDocument = new InternalDocument();
  selected_circular: InternalDocument = new InternalDocument();
  alerthelper: AlertHelper;

  public filterOptions: FilterSettingsModel;
  public checkBoxFilter: IFilter
  public fc_view: CommandModel[];
  
  constructor(private network: NetworkService, private router: Router, private dialog: MatDialog) {
    this.alerthelper = new AlertHelper(dialog);
   }

  ngOnInit() {
    this.toolbar = ['Search'];
    this.laodMemos();
    this.filterOptions = {type: 'Menu'};
    this.checkBoxFilter = {type: 'CheckBox'};
    this.fc_view = [{ buttonOption: { content: 'Preview', cssClass: 'e-flat' } }];
  }

  laodMemos(silent?: boolean){
    if(!silent && this.memos.length <= 0){
      this.memos_loader = true;
    }
    this.network.sendRequest({value: InternalDocumentType.Memo}, NetworkService.GET_INT_DOC_LIST).subscribe((res)=>{
      if(!res['error']){
        var memos: Array<any> = JSON.parse(res['response']);
        memos.forEach((item) => {
          item['status'] = this.getTreatmentStatus(item['status']);
          item['submittedAt'] = moment(item['date']).format("MMMM Do YYYY, h:mm a")
          //item['url'] = "https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf";
        })
        this.memos = memos;
      }else{
        this.alerthelper.showOkAlert("Error", res['response']);
      }
      this.memos_loader = false;
    }, (error)=>{
      this.memos_loader = false;
      this.alerthelper.showErrorAlert();
    })
  }

  loadMinutes(silent?: boolean) {
    if (!silent && this.minutes.length <= 0) {
      this.minutes_loader = true;
    }
    this.network.sendRequest({value: InternalDocumentType.Minute}, NetworkService.GET_INT_DOC_LIST).subscribe((res) => {
      if (!res['error']) {
        var minutes: Array<any> = JSON.parse(res['response']);
        minutes.forEach((item) => {
          item['submittedAt'] = moment(item['date']).format('MMMM Do YYYY, h:mm a');
        });
        this.minutes = minutes;
      } else {
        this.alerthelper.showOkAlert('Error!', res['response']);
      }
      this.minutes_loader = false;
    }, (error) => {
      this.minutes_loader = false;
      this.alerthelper.showErrorAlert();
    });
  }

  loadCirculars(silent?: boolean) {
    if (!silent && this.circulars.length <= 0) {
      this.circulars_loader = true;
    }
    this.network.sendRequest({value: InternalDocumentType.Circular}, NetworkService.GET_INT_DOC_LIST).subscribe((res) => {
      if (!res['error']) {
        var circulars: Array<any> = JSON.parse(res['response']);
        circulars.forEach((item) => {
          item['status'] = this.getTreatmentStatus(item['status']);
          item['submittedAt'] = moment(item['date']).format('MMMM Do YYYY, h:mm a');
        });
        this.circulars = circulars;
      } else {
        this.alerthelper.showOkAlert('Error!', res['response']);
      }
      this.circulars_loader = false;
    }, (error) => {
      this.circulars_loader = false;
      this.alerthelper.showErrorAlert();
    });
  }

  rowDblClickOpenMemo(args){
    var sub_ref: string = args.rowData['Ref'];
    sub_ref = sub_ref.replace("#", "");
    if(sub_ref){
      this.router.navigate(['/document',"memo", sub_ref]);
    }
  }

  rowDblClickOpenMinute(args){
    var sub_ref: string = args.rowData['Ref'];
    sub_ref = sub_ref.replace("#", "");
    if(sub_ref){
      this.router.navigate(['/document',"minute", sub_ref]);
    }
  }

  rowDblClickOpenCircular(args){
    var sub_ref: string = args.rowData['Ref'];
    sub_ref = sub_ref.replace("#", "");
    if(sub_ref){
      this.router.navigate(['/document',"circular", sub_ref]);
    }
  }

  docsLoaded(event?){
    if(this.view_memo_file_async){
      this.view_memo_file_async = false
    }
    if(this.view_minute_file_async){
      this.view_minute_file_async = false
    }
    if(this.view_circular_file_async){
      this.view_circular_file_async = false
    }
  }

  closeViewMemoFile(){
    this.view_memo_file = false;
    this.parent.showScroll();
  }

  closeViewMinuteFile(){
    this.view_minute_file = false;
    this.parent.showScroll();
  }

  closeViewCircularFile(){
    this.view_circular_file = false;
    this.parent.showScroll();
  }

  getTreatmentStatus(status){
    switch (status) {
      case TreatmentStatus.Pending:
        return '<span class="ds-yellow-text">Pending</span>';
        break;
      case TreatmentStatus.Rejected:
        return '<span class="text-danger">Rejected</span>';
        break;
      case TreatmentStatus.Treated:
        return '<span class="ds-green-text">Treated</span>';
        break;
      case TreatmentStatus.Untreated:
        return "Untreated";
        break;
      default:
        break;
    }
  }

  memoCommandClick(args: CommandClickEventArgs): void {
    this.selected_memo = JSON.parse(JSON.stringify(args.rowData));
    if(this.selected_memo && this.selected_memo.content){
      this.parent.hideScroll();
      this.view_memo_file = true;
      this.view_memo_file_async = true;
    }
  }
  minuteCommandClick(args: CommandClickEventArgs): void {
    this.selected_minute = JSON.parse(JSON.stringify(args.rowData));
    if(this.selected_minute && this.selected_minute.content){
      this.parent.hideScroll();
      this.view_minute_file = true;
      this.view_minute_file_async = true;
    }
  }
  circularCommandClick(args: CommandClickEventArgs): void {
    this.selected_circular = JSON.parse(JSON.stringify(args.rowData));
    if(this.selected_circular && this.selected_circular.content){
      this.parent.hideScroll();
      this.view_circular_file = true;
      this.view_circular_file_async = true;
    }
  }
}

class InternalDocument{
  Ref: string;
  to: string;
  subject: string;
  CreatedAt: string;
  Recepients: string;
  type: string;
  status?: string
  content: string
}
