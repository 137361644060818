import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss']
})
export class SideDrawerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleSideDrawer(){ console.log('object');
    var RightNavContent = document.querySelector('.right-nav__content');
    
    RightNavContent.classList.toggle('active');
}

}
