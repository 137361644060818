import { environment } from "./../../environments/environment";

import { Utils } from "./../helpers/utils";
import { MatDialog } from "@angular/material";
import { AlertHelper } from "./../helpers/alert-helper";
import { NetworkService } from "./../network/_services/network";
import { DashboardLayoutComponent } from "./../dashboard-layout/dashboard-layout.component";
import {
  Component,
  OnInit,
  NgModule,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy
} from "@angular/core";
import { OwlOptions, SlidesOutputData } from "ngx-owl-carousel-o";
import { ActivatedRoute, Router } from "@angular/router";
import { Helpers } from "../helpers";
import { HttpEventType, HttpEvent } from "@angular/common/http";
import {
  PageSettingsModel,
  ToolbarItems,
  EditSettingsModel,
  CommandModel,
  CommandClickEventArgs,
  RowSelectEventArgs,
  SelectionSettingsModel
} from "@syncfusion/ej2-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations/src/toolbar";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { User } from "../models/user";
import moment from "moment";
import * as $ from "jquery";
//import { PdfViewerComponent, AnnotationAddEventArgs, AnnotationPropertiesChangeEventArgs } from '@syncfusion/ej2-angular-pdfviewer';
import { ExportAsService } from '../_services/export-service';
import { ExportAsConfig } from '../_services/export-as-config.model';
import { Subscription } from 'rxjs';
import { IncomingMailService } from '../_services/incoming-mail.service';
import { FormArray, FormBuilder, Validators, FormGroup } from '@angular/forms';

declare var JQTE: any;

@Component({
  selector: "app-treat-file",
  templateUrl: "./treat-file.component.html",
  styleUrls: ["./treat-file.component.scss"]
})
export class TreatFileComponent implements OnInit, OnDestroy {
  
  loggedUser: User;
  actionInprogress = false;
  showRecpsForMessage = false;
  registryActionInprogress = false;
  rejectActionInprogress = false;
  serviceUrl = environment.pdf_service;
  minDate: Date;
  actionCommentShow = false;
  actionEvent = {
    displayName: '',
    value: 0
  };
  actionCommentMessage = '';
  addMoreFilesShow = false;
  selectedFiles: Array<File> = [];
  showFolderInfo = false;
  folder_files_show = false;
  thisFolder: any;
  file_stats_loader = false;
  folderFiles = [];
  //@ViewChild('pdfViewer', {static: false}) public pdfViewer: PdfViewerComponent;

  errorPage: boolean = false;
  errorPage_text: string = "";
  showRetryOnError: boolean = false;
  staffTreamentStatus = StaffTreatmentStatus;
  submission_ref: string = "";
  file_subs: {
    files: Array<{ fid: string; file_name: string; url: string }>;
    viewerType: ViewerType;
  } = { files: new Array(), viewerType: null };
  fsubs_comments: Array<SubmissionComment> = new Array();
  submission_info: SubmissionInfo = new SubmissionInfo();
  show_file_info: boolean = false;

  files_async: boolean = true;
  comments_async: boolean = false;

  current_doc: number = 0;
  currentSlide: SlidesOutputData;
  currentFile: {
    fid: string;
    file_name: string;
    url: string;
    annotations?: string;
  } = { fid: "", file_name: "", url: "", annotations: "" };
  parent: DashboardLayoutComponent;

  finalize_correspondence: boolean = false;

  route_dialog = {
    route: false,
    routeTitle: "",
    routeType: "",
    routeAsync: false
  };
  route_message: string = "";

  route_task_selected_files: Array<{
    fid: string;
    file_name: string;
    url: string;
  }>;

  route_submission: boolean = false;

  reject_submission: boolean = false;
  reject_submission_err: boolean = false;
  reject_submission_err_text: string = "";

  recall_file: boolean = false;
  recall_file_err: boolean = false;
  recall_file_err_text: string = "";

  departments_load_async: boolean = false;
  department_users_load_async: boolean = false;

  submission_departments: { id: string; name: string }[] = new Array();
  department_users: {
    id: string;
    name: string;
    PermissionLevel: number;
    position: string;
  }[] = new Array();

  treat_file_async: boolean = false;
  reject_file_async: boolean = false;
  recall_file_async: boolean = false;

  reject_message: string = "";
  recall_message: string = "";
  route_task_message: string = "";
  route_sub_message: string = "";

  alertHelper: AlertHelper;

  toggle_users_dropdown: boolean = true;
  selected_route_sub_user: {
    id: string;
    name: string;
    PermissionLevel: number;
    position: string;
  };
  sel_department: { id: string; name: string } = {id: '', name: ''};

  team_members: Array<ShortUserInfo> = new Array();
  routees: Array<ShortUserInfo> = new Array();
  finalize_message: string = "";
  selected_finalize_files: Array<File> = new Array();
  file_types_url = Helpers.file_types_urls;

  @ViewChild("routeFiles", { static: false }) routeFiles: ElementRef;
  selected_route_files: Array<File> = new Array();

  @ViewChild("finalizeFiles", { static: false }) finalizeFiles: ElementRef;
  finalize_async: boolean = false;
  finalize_async_err: boolean = false;
  finalize_async_err_text: string = "";
  finalize_err: boolean = false;
  finalize_err_text: string = "";
  finalize_sub_async: boolean = false;

  public pageSettings: PageSettingsModel;
  public toolbar: ToolbarItems[] | object;
  public searchToolbar: ToolbarItems[] | object = ["Search"];

  @ViewChild("teamgrid", { static: false }) public team_grid: GridComponent;
  public selectionOptions: SelectionSettingsModel;

  view_comment_files: boolean = false;
  view_comment_files_async: boolean = false;
  selected_comment_view_files: SubmissionComment = new SubmissionComment();

  route_task_files_config = {
    displayKey: "file_name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "300px", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: this.ministries.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search", // label thats displayed in search input,
    searchOnKey: "file_name" // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  config = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "200px", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: 10, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search", // label thats displayed in search input,
    searchOnKey: "name" // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  config3 = {
    displayKey:'folderName', //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select a folder for this submission', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: this.ministries.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'folderName' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  totFileSize: number;
  totFileSizeString: string;
  max_number_of_files: number = 10;

  manage_team: boolean = false;
  manage_team_loader: boolean = false;
  team_members_list: Array<TeamRouteeMember> = new Array();
  add_team_member: boolean = false;
  selected_add_team_members: { id: string; name: string }[];
  add_team_async: boolean = false;

  manage_routees: boolean = false;
  manage_routees_loader: boolean = false;
  routees_list: Array<TeamRouteeMember> = new Array();

  view_routee_response: boolean = false;
  selected_response_routee: TeamRouteeMember;

  public editSettings: EditSettingsModel;
  public commands: CommandModel[];

  comment_message: string = "";
  send_comment_async: boolean = false;

  join_team_async: boolean = false;

  token_show: boolean = false;
  token_value: string = "";
  send_token_async: boolean = false;
  token_err: boolean = false;
  token_err_text: string = "";
  token_resend_show: boolean = false;

  annot_author: string = "Me";
  
  userRoles = [];

  constructor(
    private network: NetworkService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private exportAsService: ExportAsService,
    private activeRoute: ActivatedRoute,
    private incomingMailService: IncomingMailService,
    private fb: FormBuilder
  ) {
    this.route.params.subscribe(params => {
      this.submission_ref = params["ref"];
    });
    if (!this.submission_ref) {
      this.router.navigate(["/"]);
    } else {
      this.submission_ref = this.submission_ref;
    }
    this.alertHelper = new AlertHelper(dialog);
    this.loggedUser = User.getCurrentUser();
    this.annot_author =
      this.loggedUser.FirstName + " " + this.loggedUser.LastName;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };

  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.userRoles = this.getUserRoles();
    this.initTreatFileForm();

    this.pageSettings = { pageSize: 10 };
    this.toolbar = [
      "Search",
      {
        text: "Remove member",
        tooltipText: "Remove member",
        prefixIcon: "e-delete",
        id: "rem-mem",
        align: "left"
      }
    ];
    this.editSettings = {
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowDeleting: true
    };
    this.commands = [
      {
        buttonOption: {
          content: "Remove member",
          cssClass: "e-flat",
          iconCss: "e-cancel-icon e-icons"
        }
      }
    ];
    this.selectionOptions = { type: "Multiple" };

    this.loadSubmissionDetails();
  }
  
  getUserRoles() {
    const user = this.network.getUserDetails()
    return user.uRoles ? user.uRoles : [];
  }
  userIsRegistry() {
    return this.userRoles.includes('Registry') ? true : false;
  }

  // public annotationAdd(event: AnnotationAddEventArgs){
  //   console.log(event);
  //   event.annotationSettings['author'] = this.annot_author;
  //   //this.pdfViewer.annotations
  //   console.log(this.pdfViewer.annotation);
  //   console.log(this.pdfViewer);
  //   //console.log(JSON.stringify(this.pdfViewer.annotationModule.updateAnnotationAuthor()));
  //   //this.pdfViewer.exportAnnotations()
  // }

  public annotationRemove(event) {
    console.log(event);
  }

  public annotationsChange(event) {
    console.log("update");
  }

  annotation_sync_async: boolean = false;
  // syncAnnotations(){
  //   if(!this.annotation_sync_async){
  //     this.annotation_sync_async = true;
  //     var jsonObject;
  //     var proxy = this.pdfViewer.viewerBase;
  //     jsonObject = { hashId: proxy.hashId, action: 'ExportAnnotations', pdfAnnotation: proxy.createAnnotationJsonData() };
  //     if (proxy.jsonDocumentId) {
  //         jsonObject.document = proxy.jsonDocumentId;
  //     }
  //     this.network.sendPlainRequest(jsonObject, this.serviceUrl + '/GetAnnotations').subscribe((res)=>{
  //       if(!res['error']){
  //         var response: string = res['response']
  //         var jsonData = atob(response.replace('data:application/json;base64,', ''));
  //         jsonData = this.removeDubsInAnnotationComments(jsonData);
  //         this.network.sendRequest({corrNo: this.submission_ref, fid: this.currentFile.fid}, NetworkService.PULL_ANNOTATIONS).subscribe((res) => {
  //           if(!res['error']){
  //             var current_annots = JSON.parse(jsonData);
  //             var annots = JSON.parse(res['response']);
  //             //current_annots.pdfAnnotation.push(annots.pdfAnnotation);
  //             const res_annots = {}
  //             $.extend(true, res_annots, current_annots, annots);
  //             jsonData = this.removeDubsInAnnotationComments(JSON.stringify(res_annots));
  //             this.clearAnnotations();
  //                 setTimeout(() => {
  //                   this.currentFile.annotations = jsonData;
  //                   this.pdfViewer.importAnnotations(JSON.parse(this.currentFile.annotations));
  //                 }, 500);
  //             this.network.sendRequest({corrNo: this.submission_ref, annots: jsonData, fid: this.currentFile.fid}, NetworkService.PUSH_ANNOTATIONS).subscribe((res)=>{
  //               if(!res['error']){
  //                 // this.currentFile.annotations = this.createEmptyAnnotationJsonData();
  //                 // var data = {pdfAnnotation: JSON.parse(this.currentFile.annotations)};
  //                 // this.pdfViewer.viewerBase.importedAnnotation = JSON.parse(this.createEmptyAnnotationJsonData());
  //                 // this.pdfViewer.importAnnotations(data);

  //                 // this.clearAnnotations();
  //                 // setTimeout(() => {
  //                 //   this.currentFile.annotations = res['response'];
  //                 //   this.pdfViewer.importAnnotations(JSON.parse(this.currentFile.annotations));
  //                 // }, 500);
  //                 this.alertHelper.showOkAlert("Sync Successful", "Successfully synced annotations");
  //               }else{
  //                 this.alertHelper.showOkAlert("Sync Failed", res['response']);
  //               }
  //               this.annotation_sync_async = false;
  //             }, (error)=>{
  //               this.annotation_sync_async = false;
  //               this.alertHelper.showErrorAlert();
  //             })
  //           }
  //         }, (error)=>{

  //         })
  //         // this.network.sendRequest({corrNo: this.submission_ref, annots: jsonData, fid: this.currentFile.fid}, NetworkService.SYNC_ANNOTATIONS).subscribe((res)=>{
  //         //   if(!res['error']){
  //         //     // this.currentFile.annotations = this.createEmptyAnnotationJsonData();
  //         //     // var data = {pdfAnnotation: JSON.parse(this.currentFile.annotations)};
  //         //     // this.pdfViewer.viewerBase.importedAnnotation = JSON.parse(this.createEmptyAnnotationJsonData());
  //         //     // this.pdfViewer.importAnnotations(data);
  //         //     this.clearAnnotations();
  //         //     setTimeout(() => {
  //         //       this.currentFile.annotations = res['response'];
  //         //       this.pdfViewer.importAnnotations(JSON.parse(this.currentFile.annotations));
  //         //     }, 500);
  //         //     this.alertHelper.showOkAlert("Sync Successful", "Successfully synced annotations");
  //         //   }else{
  //         //     this.alertHelper.showOkAlert("Sync Failed", res['response']);
  //         //   }
  //         //   this.annotation_sync_async = false;
  //         // }, (error)=>{
  //         //   this.annotation_sync_async = false;
  //         //   this.alertHelper.showErrorAlert();
  //         // })
  //       }else{
  //         this.annotation_sync_async = false;
  //         this.alertHelper.showErrorAlert();
  //       }
  //     }, (error)=>{
  //       this.annotation_sync_async = false;
  //       this.alertHelper.showErrorAlert();
  //     })
  //   }
  // }

  removeDubsInAnnotationComments(jsonData) {
    var dat = JSON.parse(jsonData);
    var annots = dat.pdfAnnotation;
    var keys = Object.keys(annots);
    keys.forEach(k => {
      var annot = annots[k];
      var annot_keys = Object.keys(annot);
      annot_keys.forEach(at => {
        var annot_type_comments: Array<any> = annot[at];
        var final_annot_type_comments: Array<any> = new Array();
        var foundIds: Array<any> = new Array();
        annot_type_comments.forEach(atc => {
          if (foundIds.indexOf(atc["AnnotName"]) < 0) {
            final_annot_type_comments.push(atc);
            foundIds.push(atc["AnnotName"]);
          }
        });
        annot[at] = final_annot_type_comments;
      });
    });
    return JSON.stringify(dat);
  }

  // clearAnnotations(){
  //   //this.pdfViewer.viewerBase.isImportedAnnotation = false;
  //   this.pdfViewer.viewerBase.importedAnnotation = {};
  //   $('.e-pv-comments-container').remove();
  // }
  createEmptyAnnotationJsonData = function () {
    var annotationCollection = {};
    for (var s = 0; s < this.pdfViewer.pageCount; s++) {
      var annotation = {
        textMarkupAnnotation: [],
        shapeAnnotation: [],
        measureShapeAnnotation: [],
        stampAnnotations: [],
        stickyNotesAnnotation: []
      };
      annotationCollection[s] = annotation;
    }
    return JSON.stringify(annotationCollection);
  };
  originalRecipients = [];

  loadSubmissionDetails() {
    this.files_async = true;
    this.showRetryOnError = false;
    this.errorPage = false;
    this.network.sendRequest({correspondenceNo: this.submission_ref}, NetworkService.GET_SUB_DETAILS).subscribe((res)=>{
      this.files_async = false
      if(!res['error']){
        this.submission_info = JSON.parse(res['response']);
        console.log(this.submission_info)
        this.originalRecipients = this.submission_info.recipients.split(',');
        this.show_file_info = true;
        if(this.submission_info.viewerType != ViewerType.Recipient){

        }
        this.currentFile = this.submission_info.files[0];
        this.submission_info.comments.forEach( el => {
          el.files = JSON.parse(el.files.toString());
        });
        this.submission_info.date_closed = moment(this.submission_info.date_closed).format("Do MMMM YYYY");
        if(this.userRoles.includes('Registry')) this.requestForJackets();
        // setTimeout(() => {
        //   this.initPDFViewerVars();
        // }, 500);
      }else{
        if(res['response'] == Helpers.ERR_INPUT_SUB_TOKEN){
          this.showTokenPage();
        }else{
          this.errorPage_text = res['response'];
          this.errorPage = true;
        }
      }
    });
  }

  
  pdfViewerDocLoadFailed(event){
    console.log(event)
  }

  refreshComments() {
    this.network
      .sendRequest(
        { correspondenceNo: this.submission_ref },
        NetworkService.GET_SUB_COMMENTS
      )
      .subscribe(
        res => {
          if (!res["error"]) {
            this.submission_info.comments = JSON.parse(res["response"]);
          }
        },
        error => {}
      );
  }

  getCommentInitials(comment) {
    return (
      comment.sender_fname.charAt(0).toUpperCase() +
      comment.sender_lname.charAt(0).toUpperCase()
    );
  }

  getTreatmentStatus(status: TreatmentStatus) {
    switch (status) {
      case TreatmentStatus.Pending:
        return "Pending";
        break;
      case TreatmentStatus.Rejected:
        return "Rejected";
        break;
      case TreatmentStatus.Treated:
        return "Treated";
        break;
      case TreatmentStatus.Untreated:
        return "Untreated";
        break;
      default:
        break;
    }
  }

  getFileUrgencyStatus(level) {
    switch (level) {
      case 1:
        return "Normal";
        break;
      case 2:
        return "Urgent";
        break;
      case 3:
        return "Very Urgent";
        break;
      default:
        break;
    }
  }

  docsLoaded(event) {
    if (this.files_async) {
      this.files_async = false;
    }
    //this.cd.detectChanges();
  }

  getBadgeColor(comment) {
    return comment.sender_color;
  }

  sendComment() {
    if (this.comment_message.trim().length >= 2 && !this.send_comment_async) {
      this.send_comment_async = true;
      this.network
        .sendRequest(
          {
            correspondenceNo: this.submission_ref,
            comment: this.comment_message
          },
          NetworkService.SEND_COMMENT
        )
        .subscribe(
          res => {
            this.send_comment_async = false;
            if (!res["error"]) {
              var comment: SubmissionComment = new SubmissionComment();
              comment.sender =
                this.loggedUser.FirstName +
                " " +
                this.loggedUser.LastName +
                "(" +
                this.loggedUser.Position +
                ")";
              var cdate = moment().toString();
              comment.comment_date = cdate;
              comment.comment = this.comment_message;
              comment.sender_color = this.loggedUser.UserColour;
              this.comment_message = "";
              this.submission_info.comments.unshift(comment);
            } else {
              this.alertHelper.showOkAlert("Error", res["response"]);
            }
          },
          error => {
            this.send_comment_async = false;
            this.alertHelper.showOkAlert("Error", "Unable to send comment");
          }
        );
    }
  }

  // fileToView = 0;

  prevDoc() {
    if (this.current_doc > 0) {
      this.current_doc -= 1;
      this.currentFile = this.submission_info.files[this.current_doc];
    }
  }
  nextDoc() {
    if (this.current_doc < this.submission_info.files.length - 1) {
      this.current_doc += 1;
      this.currentFile = this.submission_info.files[this.current_doc];
    }
  }

  slidePage(data: SlidesOutputData) {
    this.currentSlide = data;
    if (data.slides.length > 0) {
      this.currentFile = this.submission_info.files.find(f => {
        return f.fid == data.slides[0].id;
      });
    }
  }
  getDateString(datestring: string) {
    return this.parent.getShortDate(datestring);
  }
  getFullDate(datestring: string) {
    return this.parent.getFullDate(datestring);
  }

  routeTask() {
    this.route_message = "";
    this.route_task_selected_files = new Array();
    this.route_dialog = {
      route: true,
      routeTitle: "Delegate a task",
      routeType: "task",
      routeAsync: false
    };
    this.loadDepartments();
  }
  routeSubmission(index) {
    JQTE.start(null, { id: 'route-memo-letter-editor' });
    this.route_message = "";
    this.selected_route_files = [];
    this.route_dialog = {
      route: true,
      routeTitle: "Route this submission",
      routeType: "sub",
      routeAsync: false
    };
    this.loadDepartments(false, index);
  }

  submitRoute() {
    console.log(this.treatFileForm.value);
    if (this.sel_department == null) {
      this.alertHelper.showOkAlert("Error", "Please select user's department");
    } else if (this.treatFileForm.invalid) {
      this.alertHelper.showOkAlert("Error", "Please select a recipient or remove any unused additional recipients");
    } else if (this.route_message.trim() == "") {
      this.alertHelper.showOkAlert("Error", "Please enter a message for the recipient");
    } else {
      this.selected_route_sub_user = this.department_users.find(el => el.id == this.treatFileForm.get('Recipients').value[0].Recipient)
      if (
        this.submission_info.level >
        this.selected_route_sub_user.PermissionLevel
      ) {
        this.alertHelper.showYesNoAlert(
          "Level Mismatch",
          'This file has a confidentiality level of <span class="text-danger">' +
            Helpers.getConfidentialityLevelText(this.submission_info.level) +
            '</span><br/> and the first recipient has a confidentiality of <span class="text-danger">' +
            Helpers.getConfidentialityLevelText(this.submission_info.level) +
            "<br/> Would you like to continue?",
          () => {
            this.completeSubmitRoute();
          }
        );
      } else {
        this.completeSubmitRoute();
      }
    }
  }
  dueDate = '';

  async completeSubmitRoute() {
    this.route_dialog.routeAsync = true;
    var file_ids: Array<string> = new Array();
    var data = {};
    data["CorrNo"] = this.submission_ref;
    var url: string = "";
    if (this.route_dialog.routeType == "task") {
      this.route_task_selected_files.forEach(f => {
        file_ids.push(f.fid);
      });
      data["TaskRecipientId"] = this.selected_route_sub_user.id;
      data["SendMessage"] = this.route_message;
      url = NetworkService.ROUTE_TASK;
    } else {
      const recps = this.treatFileForm.get('Recipients').value;
      let recpsId = [];
      recps.forEach(el => {
        recpsId.push(el.Recipient);
      });
      data["recepientId"] = recps[0].Recipient;
      data["recipients"] = recpsId;
      data["message"] = this.route_message;
      data["corrNo"] = this.submission_ref;
      if (this.dueDate !== '') {
        data["dueDate"] = moment(this.dueDate).format("YYYY/MM/DD");
      } else {
        // data['dueDate'] = null;
      }
      url = NetworkService.TREAT_FILE;
    }
    data["AttachedFileIds"] = file_ids;
    if (JQTE.getValue("#mem-let-doc") != "") {
      //var body = document.getElementsByTagName('body')[0]

      var pdfEle = document.createElement("div");
      pdfEle.setAttribute("id", "fake-pdf-element");
      pdfEle.style.top = "0";
      pdfEle.style.height = "27.9cm";
      pdfEle.style.width = "100%";

      var htmlContent = document.createElement("html");
      htmlContent.style.height = "27.9cm";
      htmlContent.style.minHeight = "100%";
      var body = document.createElement("body");
      body.style.height = "27.9cm";
      body.style.minHeight = "100%";
      htmlContent.appendChild(body);
      body.appendChild(pdfEle);

      pdfEle.innerHTML = Helpers.formatUnicorn(
        Helpers.NEW_MEMO_IN_TREAT_FILE,
        this.loggedUser.FirstName + " " + this.loggedUser.LastName,
        this.selected_route_sub_user.name,
        moment().format("MMMM Do YYYY"),
        this.submission_info.subject,
        JQTE.getValue("#mem-let-doc"),
      );

      console.log(htmlContent.outerHTML);

      var exportAsConfig: ExportAsConfig = {
        type: "pdf", // the type you want to download
        //elementId: 'fake-pdf-element', // the id of html/table element
        html: htmlContent.outerHTML
      };
      var __this = this;
      await this.exportAsService
        .get(exportAsConfig)
        .subscribe(async content => {
          var re = /(-)+/gi;
          Utils.urltoFile(
            content,
            "Generated_" + Utils.createGUID().replace(re, "") + ".pdf",
            "application/pdf"
          ).then(function(file) {
            __this.selected_route_files.push(file);
            __this.completeTreatFile(data, url);
          });
        });
    } else {
      this.completeTreatFile(data, url);
    }
  }

  completeTreatFile(data, url) {
    if (
      this.selected_route_files.length > 0 &&
      this.route_dialog.routeType == "sub"
    ) {
      this.network
        .filesUploadKey(this.selected_route_files, url, data)
        .subscribe(res => {
          this.handleTreatFileUploadProgress(res);
        });
    } else {
      this.network.sendRequest(data, url).subscribe(
        res => {
          this.route_dialog.routeAsync = false;
          if (!res["error"]) {
            this.route_dialog.route = false;
            if (this.route_dialog.routeType == "sub") {
              var comment: SubmissionComment = new SubmissionComment();
              comment.sender =
                this.loggedUser.FirstName +
                " " +
                this.loggedUser.LastName +
                " (" +
                this.loggedUser.Position +
                ")";
              comment.recipient = this.selected_route_sub_user.name;
              var cdate = moment().toString();
              comment.comment_date = cdate;
              comment.comment = this.route_message;
              comment.sender_color = this.loggedUser.UserColour;
              this.comment_message = "";
              this.submission_info.comments.unshift(comment);
              this.parent.reloadDashBoardVars();
            }
            this.alertHelper.showOkAlert("Success", res["response"]);
            this.loadSubmissionDetails();
          } else {
            this.alertHelper.showOkAlert("Error", res["response"]);
          }
        },
        error => {
          this.route_dialog.routeAsync = false;
          this.alertHelper.showErrorAlert();
        }
      );
    }
  }

  focu(event: FocusEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  handleTreatFileUploadProgress(event) {
    if (event.type === HttpEventType.Response) {
      var serverResponse = event.body;
      if (!serverResponse["error"]) {
        this.route_dialog.route = false;
        if (this.route_dialog.routeType == "sub") {
          var comment: SubmissionComment = new SubmissionComment();
          comment.sender =
            this.loggedUser.FirstName +
            " " +
            this.loggedUser.LastName +
            "(" +
            this.loggedUser.Position +
            ")";
          comment.recipient = this.selected_route_sub_user.name;
          var cdate = moment().toString();
          comment.comment_date = cdate;
          comment.comment = this.route_message;
          comment.sender_color = this.loggedUser.UserColour;
          comment.files = JSON.parse(serverResponse["response"]);
          this.submission_info.comments.unshift(comment);

          this.selected_route_files = [];
          this.parent.reloadDashBoardVars();
        }
        this.alertHelper.showOkAlert("Success", "File Forwarded Successfully");
        this.loadSubmissionDetails();
      } else {
        this.alertHelper.showOkAlert("Error", serverResponse["response"]);
      }
    }
  }

  loadDepartments(team?: boolean, index?: number) {
    this.refreshDepartmentUsers();
    var _team = "0";
    if (team) {
      _team = "1";
      this.selected_add_team_members = null;
    }
    if (this.submission_departments.length <= 0) {
      this.departments_load_async = true;
      this.network
        .sendRequest(
          { },
          NetworkService.GET_MINISTRY_DEPT
        )
        .subscribe(
          res => {
            this.departments_load_async = false;
            if (!res["error"]) {
              var deps = JSON.parse(res["response"]);
              this.submission_departments = deps;
              this.sel_department = this.submission_departments.find(el => el.name === this.loggedUser.DepartmentName);
              
              if (index >= 0) {
                this.getRecipients.controls[index].get('Department').patchValue(this.sel_department.id);
                // console.log(this.sel_department);
                this.submmisionDepartmentsChange(this.sel_department, index);
              } else {
                // this.getRecipients.controls[index].get('Department').patchValue(this.sel_department.id);
                // console.log(this.sel_department);
                this.submmisionDepartmentsChange(this.sel_department);
              }
            }
          },
          error => {
            this.departments_load_async = false;
          }
        );
    }
  }

  showFinalizeCorrespondence() {
    this.finalize_correspondence = true;
    this.loadFinalizeVars();
  }

  getLoggedInUserDepartment(dept) {
    const res = dept.name === this.loggedUser.DepartmentName;
    return res;
  }

  loadFinalizeVars() {
    this.finalize_async_err = false;
    if (
      this.submission_info.viewerType == ViewerType.CurrentTreater ||
      this.submission_info.viewerType == ViewerType.Recipient ||
      this.submission_info.viewerType == ViewerType.BaseRecipient
    ) {
      this.finalize_async = true;
      this.network
        .sendRequest(
          { correspondenceNo: this.submission_ref },
          NetworkService.GET_SUB_FINALIZE_MEMBERS
        )
        .subscribe(
          res => {
            this.finalize_async = false;
            if (!res["error"]) {
              var team_routees = JSON.parse(res["response"]);
              this.team_members = team_routees["team"];
              this.routees = team_routees["routees"];
            } else {
              this.finalize_async_err = true;
              this.finalize_async_err_text = res["reponse"];
            }
          },
          error => {
            this.finalize_async = false;
            this.finalize_async_err = true;
            this.finalize_async_err_text = "Sorry, An unexpected error occured";
          }
        );
    }
  }

  checkFinalizeFilesForFinalizeCorresspondence() {
    if (this.selected_finalize_files.length <= 0) {
      this.alertHelper.showYesNoAlert(
        "Confirm",
        "Are you sure you want to finalize this correspondence without files?",
        () => {
          this.finalizeCorrespondence();
        }
      );
      return;
    } else {
      this.finalizeCorrespondence();
    }
  }

  finalizeCorrespondence() {
    var team_helpful: Array<string> = new Array();
    var routee_helpful: Array<string> = new Array();

    var data = {};
    var url = NetworkService.FINALIZE_CORRESPONDENCE;

    if (
      this.submission_info.viewerType == ViewerType.CurrentTreater ||
      this.submission_info.viewerType == ViewerType.Recipient ||
      this.submission_info.viewerType == ViewerType.BaseRecipient
    ) {
      if (this.team_members.length > 0) {
        var no_helpful_set = this.team_members.find(tm => {
          return typeof tm.helpful == "undefined" || tm.helpful.trim() == "";
        });
        if (no_helpful_set) {
          this.alertHelper.showOkAlert(
            "Error",
            "You must select helpful or not helpful for every team member"
          );
          return;
        }
        this.team_members.forEach(tm => {
          if (tm.helpful == "1") {
            team_helpful.push(tm.id.toString());
          }
        });
      }
      if (this.routees.length > 0) {
        var no_helpful_set = this.routees.find(tm => {
          return typeof tm.helpful == "undefined" || tm.helpful.trim() == "";
        });
        if (no_helpful_set) {
          this.alertHelper.showOkAlert(
            "Error",
            "You must select helpful or not helpful for every routee"
          );
          return;
        }
        this.routees.forEach(rt => {
          if (rt.helpful == "1") {
            routee_helpful.push(rt.id.toString());
          }
        });
      }

      data["team_helpful"] = team_helpful;
      data["routee_helpful"] = routee_helpful;
    } else {
      return;
    }

    data["CorrNo"] = this.submission_ref;
    if (this.finalize_message.trim() != "") {
      data["message"] = this.finalize_message;
    }

    this.finalize_sub_async = true;
    if (this.selected_finalize_files.length > 0) {
      this.network
        .filesUploadKey(this.selected_finalize_files, url, data)
        .subscribe(
          res => {
            this.handleFinalizeProgress(res);
          },
          error => {
            this.finalize_sub_async = false;
            this.alertHelper.showErrorAlert();
          }
        );
    } else {
      this.network.sendRequest(data, url).subscribe(
        res => {
          this.finalize_sub_async = false;
          if (!res["error"]) {
            this.finalizeDone();
          } else {
            this.finalize_sub_async = false;
            this.alertHelper.showOkAlert("Error", res["response"]);
          }
        },
        error => {
          this.finalize_sub_async = false;
          this.alertHelper.showErrorAlert();
        }
      );
    }
  }

  handleFinalizeProgress(event) {
    if (event.type === HttpEventType.Response) {
      this.finalize_sub_async = false;
      var serverResponse = event.body;
      if (!serverResponse["error"]) {
        this.finalizeDone();
      } else {
        this.finalize_sub_async = false;
        this.alertHelper.showOkAlert("Error", serverResponse["response"]);
      }
    }
  }

  finalizeDone() {
    this.finalize_correspondence = false;
    this.finalize_message = "";
    this.selected_finalize_files = new Array();
    this.finalize_sub_async = false;
    this.alertHelper.showOkAlert(
      "Success",
      "Correspondence finalized successfully",
      () => {
        this.router.navigate(["/"]);
      },
      5000
    );
    //this.router.navigate(['/']);
  }

  browseFinalizeFiles() {
    var fin_files: HTMLInputElement = this.finalizeFiles.nativeElement;
    fin_files.click();
  }

  finalizeFilesChange() {
    var finalizeFiles = this.finalizeFiles.nativeElement;
    var fileError: boolean = false;
    if (finalizeFiles.files) {
      var total_file_count =
        this.selected_finalize_files.length + finalizeFiles.files.length;
      if (total_file_count <= this.max_number_of_files) {
        for (var i = 0; i < finalizeFiles.files.length; i++) {
          if (this.file_types_url[finalizeFiles.files[i].type]) {
            this.selected_finalize_files.push(finalizeFiles.files[i]);
            var file_size = +(finalizeFiles.files[i].size / 1024 / 1024);
            this.totFileSize += file_size;
            this.totFileSizeString = this.totFileSize.toFixed(2) + "MB";
          } else {
            fileError = true;
            finalizeFiles.value = "";
          }
        }
        if (fileError) {
          this.alertHelper.showOkAlert(
            "Skipped files",
            "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format"
          );
        }
      } else {
        this.alertHelper.showOkAlert(
          "Skipped files",
          "You have reached the maximum number of files"
        );
      }
      finalizeFiles.value = "";
    } else {
      this.selected_finalize_files = new Array();
    }
  }
  removeFinalizeFiles() {
    var finalizeFiles = this.finalizeFiles.nativeElement;
    finalizeFiles.value = "";
    this.selected_finalize_files = new Array();
  }

  helpfulStatusChange(event, member: ShortUserInfo) {
    member.helpful = event.target.value;
  }

  rejectSubmission() {
    this.reject_submission_err = false;
    this.reject_submission = true;
  }

  recallFile() {
    this.recall_file_err = false;
    this.recall_file = true;
  }

  submitRejectSubmission(){
    if((this.submission_info.viewerType == ViewerType.Treater || this.submission_info.viewerType == ViewerType.BaseTreater || this.submission_info.viewerType == ViewerType.BaseRecipient) && this.submission_info.treatment_status == TreatmentStatus.Untreated){
      this.reject_submission_err = false;
      var url = NetworkService.REJECT_SUBMISSION;
      var data;
      if(this.reject_message.trim() == ""){
        this.reject_submission_err = true;
        this.reject_submission_err_text = "Please enter a rejection message";
        return;
      }
      data = {corrNo: this.submission_ref, rejectMessage: this.reject_message};

      this.reject_file_async = true;

      this.network.sendRequest(data, url).subscribe((res) => {
        this.reject_file_async = false;
        if(!res['error']){
          this.reject_submission = false;
          this.alertHelper.showOkAlert("Success", "Correspondence rejected successfully", () =>{this.router.navigate(['/'])}, 8000);
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.reject_file_async = false;
        this.alertHelper.showErrorAlert();
      })
    }
  }


  submitRecallFile(){
    this.recall_file_err = false;
    var url = NetworkService.RECALL_SUBMISSION;
    var data;
    if (this.recall_message.trim() == "") {
      this.recall_file_err = true;
      this.recall_file_err_text = "Please enter reason for recall";
      return;
    }
    if(this.submission_info.viewerType == ViewerType.PreviousTreater) {
      data = {
        corrNo: this.submission_ref,
        message: this.recall_message
      };
    } else {
      this.alertHelper.showErrorAlert();
      return;
    }

    this.recall_file_async = true;
    this.network.sendRequest(data, url).subscribe(
      (res) => {
        this.recall_file_async = true;

        if (!res["error"]) {
          this.recall_file_async = false;
          this.recall_file = false;
          this.alertHelper.showOkAlert("Success", "File Recalled Succesfully");
          this.loadSubmissionDetails();
        } else {
          this.alertHelper.showOkAlert("Error", res["response"]);
        }
      },
      error => {
        this.recall_file_async = false;
        this.alertHelper.showErrorAlert();
      }
    );
  }
  submmisionDepartmentsChange(event, index?: number) {
    this.refreshDepartmentUsers();
    this.sel_department = event;
    if (event) {
      this.department_users_load_async = true;
      this.network
        .sendRequest(
          { did: event.id },
          NetworkService.GET_DEPARTMENT_USERS_PLAIN
        )
        .subscribe(
          res => {
            this.department_users_load_async = false;
            if (!res["error"]) {
              var usrs = JSON.parse(res["response"]);
              this.department_users = usrs;
              if (index >= 0) {
                this.getRecipients.controls[index].get('Recipient').patchValue(this.department_users[0].id);
              }
            }
          },
          error => {
            this.department_users_load_async = false;
          }
        );
    }
  }

  refreshDepartmentUsers() {
    this.toggle_users_dropdown = false;
    this.selected_route_sub_user = null;
    this.department_users = new Array();
    setTimeout(() => {
      this.toggle_users_dropdown = true;
    }, 1);
  }
  treatFileForm: FormGroup;



  initTreatFileForm() {
    this.treatFileForm = this.fb.group({
      Recipients: this.fb.array([
        this.fb.group({
          Recipient: [null, Validators.required],
          Department: [null, Validators.required]
        })
      ])
    });
  }

  get getRecipients() {
    return this.treatFileForm.get('Recipients') as FormArray;
  }

  addMoreRecipient(index) {
    this.sel_department = this.submission_departments.find(el => el.name === this.loggedUser.DepartmentName);
    this.getRecipients.controls[index].get('Department').patchValue(this.sel_department.id);
    this.getRecipients.push(
        this.fb.group({
          Recipient: ['', Validators.required],
          Department: [null, Validators.required]
        })
      );
  }

  removeRecipient(index) {
    this.getRecipients.removeAt(index);
  }

  treatFile() {
    this.treat_file_async = true;
    var url = "";
    if (this.submission_info.viewerType == ViewerType.Recipient) {
      url = NetworkService.ACCEPT_SUBMISSION;
    } else if (
      this.submission_info.viewerType == ViewerType.SecondaryRecipient
    ) {
      url = NetworkService.SEC_ACCEPT_SUBMISSION;
    } else {
      return;
    }
    this.network.sendRequest({ CorrNo: this.submission_ref }, url).subscribe(
      res => {
        this.treat_file_async = false;
        if (!res["error"]) {
          this.submission_info = JSON.parse(res["response"]);
          this.show_file_info = true;
        } else {
          this.alertHelper.showOkAlert("Error", res["response"]);
        }
      },
      error => {
        this.treat_file_async = false;
        this.alertHelper.showErrorAlert();
      }
    );
  }

  showRouteeResponse(routee: TeamRouteeMember) {
    if (routee) {
      this.selected_response_routee = routee;
      this.view_routee_response = true;
    }
  }

  showManageRoutees() {
    this.loadRoutees();
    this.manage_routees = true;
  }

  loadRoutees() {
    this.manage_routees_loader = true;
    this.network
      .sendRequest(
        { correspondenceNo: this.submission_ref },
        NetworkService.GET_ROUTEES
      )
      .subscribe(
        res => {
          this.manage_routees_loader = false;
          if (!res["error"]) {
            this.routees_list = JSON.parse(res["response"]);
          }
        },
        error => {
          this.manage_routees_loader = false;
        }
      );
  }

  confirmRevokeAccess(routee: TeamRouteeMember) {
    //if(routee.status == RouteStatus.Pending || routee.status == RouteStatus.Untreated){
    this.alertHelper.showYesNoAlert(
      "Revoke Access?",
      "Are you sure you want to revoke this routee's access?",
      () => {
        this.revokeAccess(routee);
      }
    );
    //}
  }

  revokeAccess(routee: TeamRouteeMember) {
    var temp_routee_list = JSON.parse(JSON.stringify(this.routees_list));
    this.routees_list = this.routees_list.filter(r => {
      r.id != routee.id;
    });
    var ids: string[] = [];
    ids.push(routee.id.toString());
    this.network
      .sendRequest(
        { corrNo: this.submission_ref, id: ids },
        NetworkService.REVOKE_ACCESS
      )
      .subscribe(
        res => {
          if (res["error"]) {
            this.routees_list = temp_routee_list;
            this.alertHelper.showOkAlert("Error", res["response"]);
          } else {
            this.alertHelper.showOkAlert("Success", res["response"]);
          }
        },
        error => {
          this.routees_list = temp_routee_list;
          this.alertHelper.showErrorAlert();
        }
      );
  }

  showManageteam() {
    this.loadDepartments(true);
    this.loadTeamMembers();
    this.manage_team = true;
  }

  toggleAddMember() {
    this.add_team_member = !this.add_team_member;
  }

  loadTeamMembers() {
    // this.manage_team_loader = true;
    // setTimeout(() => {
    //   for (let index = 1; index < 5; index++) {
    //     var tm = new TeamMember();
    //     tm.id = index;
    //     tm.email = "dappyfresh" + index + "@gmail.com";
    //     tm.name = "Dappy Fresh " + index;
    //     this.team_members_list.push(tm);
    //   }
    //   this.manage_team_loader = false;
    // }, 2000);
    this.manage_team_loader = true;
    this.network
      .sendRequest(
        { correspondenceNo: this.submission_ref },
        NetworkService.GET_TEAM_MEMBERS
      )
      .subscribe(
        res => {
          this.manage_team_loader = false;
          if (!res["error"]) {
            this.team_members_list = JSON.parse(res["response"]);
          }
        },
        error => {
          this.manage_team_loader = false;
        }
      );
  }

  toolBarClick(args: ClickEventArgs) {
    //console.log(args);
    if (args.item.id == "rem-mem") {
      const selectedrecords: object[] = this.team_grid.getSelectedRecords(); // Get the selected records.
      if (selectedrecords.length <= 0) {
        this.alertHelper.showOkAlert(
          "No user selected",
          "No team member selected to remove"
        );
      } else {
        this.alertHelper.showYesNoAlert(
          "Remove Member",
          "Are you sure you want to remove this member from your team",
          () => {
            this.removeMembers(selectedrecords);
          }
        );
      }
    }
  }
  removeMembers(_members: object[]) {
    this.add_team_async = true;
    var members: TeamRouteeMember[] = JSON.parse(JSON.stringify(_members));
    var memIds: Array<string> = new Array();
    members.forEach(mem => {
      memIds.push(mem.id.toString());
    });
    this.network
      .sendRequest(
        { CorrNo: this.submission_ref, MemberIds: memIds },
        NetworkService.TEAM_REMOVE_MEMBERS
      )
      .subscribe(
        res => {
          this.add_team_async = false;
          if (!res["error"]) {
            this.team_members_list = JSON.parse(res["response"]);
            this.alertHelper.showOkAlert(
              "Success",
              "Removed selected members successfully"
            );
          } else {
            this.alertHelper.showOkAlert("Error", res["response"]);
          }
        },
        error => {
          this.add_team_async = false;
          this.alertHelper.showErrorAlert();
        }
      );
  }
  addMembers() {
    if (
      this.selected_add_team_members &&
      this.selected_add_team_members.length > 0
    ) {
      this.add_team_async = true;
      var memIds: Array<string> = new Array();
      this.selected_add_team_members.forEach(mem => {
        memIds.push(mem.id.toString());
      });
      this.network
        .sendRequest(
          { CorrNo: this.submission_ref, MemberIds: memIds },
          NetworkService.TEAM_ADD_MEMBERS
        )
        .subscribe(
          res => {
            this.add_team_async = false;
            if (!res["error"]) {
              this.alertHelper.showOkAlert(
                "Success",
                "Added selected users successfully"
              );
              this.manage_team = false;
              this.team_members_list = JSON.parse(res["response"]);
            } else {
              this.alertHelper.showOkAlert("Error", res["response"]);
            }
          },
          error => {
            this.add_team_async = false;
            this.alertHelper.showErrorAlert();
          }
        );
    } else {
      this.alertHelper.showOkAlert(
        "Error",
        "Please select at least one user to send a request to"
      );
    }
  }
  commandClick(args: CommandClickEventArgs): void {
    alert(JSON.stringify(args.rowData));
  }

  rowSelected(args: RowSelectEventArgs) {
    const selectedrowindex: number[] = this.team_grid.getSelectedRowIndexes(); // Get the selected row indexes.
    const selectedrecords: object[] = this.team_grid.getSelectedRecords(); // Get the selected records.
  }

  joinTeam() {
    this.join_team_async = true;
    this.network
      .sendRequest(
        { CorrNo: this.submission_ref },
        NetworkService.TEAM_ACCEPT_REQUEST
      )
      .subscribe(
        res => {
          this.join_team_async = false;
          if (!res["error"]) {
            this.submission_info = JSON.parse(res["response"]);
            this.show_file_info = true;
          } else {
            this.alertHelper.showOkAlert("Error", res["response"]);
          }
        },
        error => {
          this.join_team_async = false;
          this.alertHelper.showErrorAlert();
        }
      );
  }

  confirmRejectRequest() {
    this.alertHelper.showYesNoAlert(
      "Reject?",
      "Are you sure you want to reject this team request?",
      () => {
        this.rejectTeamRequest();
      }
    );
  }

  rejectTeamRequest() {
    this.network
      .sendRequest(
        { CorrNo: this.submission_ref },
        NetworkService.TEAM_REJECT_REQUEST
      )
      .subscribe(
        res => {
          this.reject_file_async = false;
          if (!res["error"]) {
            this.router.navigate(["/"]);
          } else {
            this.alertHelper.showOkAlert("Error", res["response"]);
          }
        },
        error => {
          this.reject_file_async = false;
          this.alertHelper.showErrorAlert();
        }
      );
  }
  confirmLeaveTeam() {
    this.alertHelper.showYesNoAlert(
      "Reject?",
      "Are you sure you want to reject this team request?",
      () => {
        this.leaveTeam();
      }
    );
  }

  leaveTeam() {
    this.network
      .sendRequest({ CorrNo: this.submission_ref }, NetworkService.LEAVE_TEAM)
      .subscribe(
        res => {
          this.reject_file_async = false;
          if (!res["error"]) {
            this.router.navigate(["/"]);
          } else {
            this.alertHelper.showOkAlert("Error", res["response"]);
          }
        },
        error => {
          this.reject_file_async = false;
          this.alertHelper.showErrorAlert();
        }
      );
  }

  browseRouteFiles() {
    var fin_files: HTMLInputElement = this.routeFiles.nativeElement;
    fin_files.click();
  }

  routeFilesChange() {
    var routeFiles = this.routeFiles.nativeElement;
    var fileError: boolean = false;
    if (routeFiles.files) {
      var total_file_count =
        this.selected_route_files.length + routeFiles.files.length;
      if (total_file_count <= this.max_number_of_files) {
        for (var i = 0; i < routeFiles.files.length; i++) {
          if (this.file_types_url[routeFiles.files[i].type]) {
            this.selected_route_files.push(routeFiles.files[i]);
          } else {
            fileError = true;
            routeFiles.value = "";
          }
        }
        if (fileError) {
          this.alertHelper.showOkAlert(
            "Skipped files",
            "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format"
          );
        }
      } else {
        this.alertHelper.showOkAlert(
          "Skipped files",
          "You have reached the maximum number of files"
        );
      }
    } else {
      this.selected_finalize_files = new Array();
    }
  }
  removeRouteFiles() {
    var routeFiles = this.routeFiles.nativeElement;
    routeFiles.value = "";
    this.selected_route_files = new Array();
  }

  showTokenPage() {
    this.token_value = "";
    this.token_show = true;
    this.token_resend_show = false;
    setTimeout(() => {
      if (!this.send_token_async) {
        this.token_resend_show = true;
      }
    }, 5000);
  }

  closeTokenPage() {
    this.token_show = false;
    this.router.navigate(["/"]);
  }

  resendToken() {
    this.loadSubmissionDetails();
  }

  tokenInputKeyPress(event) {
    Utils.numberFieldKeyPress(event);
    if (event.keyCode == 13) {
      this.submitToken();
    }
  }
  tokenFieldInput() {
    var isNumber = Utils.numberFieldInput(this.token_value);
    if (!isNumber) {
      var re = /([^0-9])/gi;
      this.token_value = this.token_value.replace(re, "");
      setTimeout(() => {
        //this.token_value = this.token_value.trim();
        this.cd.detectChanges();
      }, 10);
    }
  }

  submitToken() {
    this.token_err = false;
    if (this.token_value.trim() == "") {
      this.token_err = true;
      this.token_err_text = "Please enter a token";
    } else if (this.token_value.trim().length < 5) {
      return;
    } else if (isNaN(parseInt(this.token_value))) {
      return;
    } else {
      this.send_token_async = true;
      this.network
        .sendRequest(
          { token: this.token_value, corrNo: this.submission_ref },
          NetworkService.CONFIRM_SUB_TOKEN
        )
        .subscribe(
          res => {
            this.send_token_async = false;
            if (!res["error"]) {
              this.files_async = false;
              this.submission_info = JSON.parse(res["response"]);
              this.show_file_info = true;
              this.currentFile = this.submission_info.files[0];
              this.token_show = false;
              this.alertHelper.showOkAlert(
                "Token Confirmed",
                "Your token has been confirmed successfully"
              );
            } else {
              this.token_err = true;
              this.token_err_text = res["response"];
            }
          },
          error => {
            this.send_token_async = false;
            this.token_err = true;
            this.token_err_text = "Sorry an unexpected error occured";
          }
        );
    }
  }

  currentCommentFile = 0;
  prevCommentDoc() {
    if (this.currentCommentFile > 0) {
      this.currentCommentFile -= 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }
  nextCommentDoc() {
    if (this.currentCommentFile < this.selected_comment_view_files.files.length - 1) {
      this.currentCommentFile += 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }

  showCommentFiles(comm) {
    if (comm && comm.files && comm.files.length > 0) {
      this.view_comment_files = true;
      this.view_comment_files_async = true;
      this.selected_comment_view_files = comm;
      this.parent.hideScroll();
    }
  }
  closeCommentFiles() {
    this.view_comment_files = false;
    this.parent.showScroll();
  }
  commFilesLoaded(event) {
    if (this.view_comment_files_async) {
      this.view_comment_files_async = false;
    }
  }

  // --- //
  createNewJacket = false;
  selectedDepartmentForJacketCreation = 0;
  folderNameForJacketCreation = ''
  departmentsInMinistry: any[] = [];
  selectedFolderObject: any;
  selectedFolder = 0;
  sub: Subscription = new Subscription();
  setSelectedFolder(event) {
    console.log(event)
    // get folder qr code
    this.sub.add(
      this.network.sendRequest({FolderId: event.value.id}, 'GetFolderById').subscribe({
        next: response => {
          this.selectedFolderObject = JSON.parse(response['response'])
        },
        error: e => {
          console.log(e)
        }
      })
    )    
  }
  showQRCode = false;

  createFileJacket() {
    this.create_loader = true;
    // send request to api
    this.sub.add(
      this.network.sendFileJacketRequest({FolderName: this.folderNameForJacketCreation, DepartmentId: this.selectedDepartmentForJacketCreation}, NetworkService.CREATE_NEW_FOLDER).subscribe({
        next: response => {
          if(response['error']) {
            this.create_loader = false;
            return this.alertHelper.showOkAlert('Failed', response['response'])
          }
          this.create_loader = false;
          this.selectedFolderObject = response;
          // this.showQRCode = true;
          this.createNewJacket = false;
          this.foldersAvailable =
          [...this.foldersAvailable,
            {
              id: this.selectedFolderObject.Id,
              folderName: this.selectedFolderObject.FolderName,
              department: this.selectedFolderObject.DepartmentName
            }
          ]
          // console.log(this.foldersAvailable);
          this.requestForJackets();
          this.alertHelper.showOkAlert('Success', 'Folder created successfully.');
        },
        error: e => {
          this.create_loader = false;
          return this.alertHelper.showOkAlert('Failed', 'Failed to Add Folder, Please try later')
        }
      })
    )
  }
  add_loader = false;
  create_loader = false;

  addSubmissionToJacket() {
    // send request to api
    // redirect back to dashboard.
    this.add_loader = true;
    this.sub.add(
      this.network.sendFileJacketRequest({SubmissionId: this.submission_info.id, FolderId: this.selectedFolder}, NetworkService.ADD_TO_FOLDER).subscribe({
        next: res => {
          if(!res['error']) {
            this.add_loader = false;
            this.alertHelper.showOkAlert('Success', 'File Added to the specified folder.', ()=> {
              this.parent.reloadDashBoardVars();
              this.router.navigate(['/dashboard']);
            })
          }
        },
        error: e => {
          this.add_loader = false;
          this.alertHelper.showErrorAlert();
        }
      })
    )    
  }

  loadDepartmentsInMinistry() {
    this.sub.add(
      this.network.sendRequest({}, 'get-ministry-department').subscribe({
      next: (response: any) => {
        this.departmentsInMinistry = JSON.parse(response.response);
      },
      error: (e) => {
        console.log(e);
      }
      })
    )    
  }
  foldersAvailable: any[];
  selectedFolders: {id: number, folderName: string}[];
  requestForJackets() {
    this.sub.add(
      this.network.sendFileJacketRequest({}, NetworkService.GET_ALL_FOLDERS).subscribe(
        resp => {
          if(!resp['error']) {
            this.foldersAvailable = JSON.parse(resp['response']);
            this.loadDepartmentsInMinistry();
          } else {
            this.alertHelper.showOkAlert('Error', resp['response'])
          }
        }, error => {
          this.alertHelper.showErrorAlert();
        }
      )
    )    
  }
  toggleShowFolderInfo() {
    this.showFolderInfo = !this.showFolderInfo;
  }
  closeFileStats(){
    this.folder_files_show = false;
  }

  viewFolderFiles(folder) {
    this.thisFolder = folder;
    this.folder_files_show = true;
    this.file_stats_loader = true;
    this.network.sendFileJacketRequest({FolderId: this.thisFolder.id}, NetworkService.GETFOLDERSUBMISSIONS).subscribe(result => {
      // console.log(JSON.parse(result['response']));
      this.folderFiles = JSON.parse(result['response'])
      this.folderFiles.forEach(el => {
        el.CreatedAt = moment(el.CreatedAt).format("Do MMMM YYYY")
      })
      this.folderFiles = this.folderFiles.filter(el => {
        return el.CorrespondenceNo !== this.submission_ref;
      })
      this.file_stats_loader = false;
    })
  }

  performActionOnDoc(type) {
    switch (type) {
      case 1: {
        this.actionEvent = {
          displayName: 'Work In Progress',
          value: 2
        }
        this.actionCommentShow = true;
        break;
      }
      case 2: {
        this.actionEvent = {
          displayName: 'Completed',
          value: 4
        }
        this.actionCommentShow = true;
        break;
      }
      case 3: {
        this.actionEvent = {
          displayName: 'Put Away',
          value: 7
        }
        this.actionCommentShow = true;
        break;
      }
      case 4: {
        this.actionEvent = {
          displayName: 'Keep In View',
          value: 6
        }
        this.actionCommentShow = true;
        break;
      }
      case 5: {
        this.actionEvent = {
          displayName: 'Additional Information Required',
          value: 3
        }
        this.actionCommentShow = true;
        break;
      }
    }
  }

  closeDocAction() {
    this.actionCommentShow = false;
    this.actionEvent = {
      displayName: '',
      value: 0
    };
    this.actionCommentMessage = '';
  }

  changeStaffFileStatus(status) {
    if (this.actionCommentMessage === '') {
      return this.alertHelper.showOkAlert('Error', 'You need to enter a comment to proceed!');
    }
    const payload = {
      fileSubmissionId: this.submission_info.id,
      status,
      comment: this.actionCommentMessage
    };
    const WIPpayload = {
      fileSubmissionId: this.submission_info.id,
      comment: this.actionCommentMessage
    };
    switch (status) {
      case 2:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(WIPpayload, NetworkService.CHANGE_STAFF_WIP_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Your task on this Issue was succesfully marked as 'In Progress'`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 3:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_STAFF_FILE_STATUS).subscribe(
            {
              next: result => {
                this.actionInprogress = false;
                this.alertHelper.showOkAlert('Success', `Document returned to the registry requiring additional information`, ()=> {
                  this.parent.reloadDashBoardVars();
                  this.router.navigate(['dashboard']);
                })
              },
              error: e => {
                this.actionInprogress = false;
                this.alertHelper.showErrorAlert();
              }
            }
            )
          );
          break;
        }
      case 4:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_STAFF_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Document returned to the registry as 'Completed'`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 6:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_STAFF_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Document returned to the registry with the instruction to 'Keep In View'`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      case 7:
        {
          this.actionInprogress = true;
          this.sub.add(
            this.network.sendRequest(payload, NetworkService.CHANGE_STAFF_FILE_STATUS).subscribe(
              {
                next: result => {
                  this.actionInprogress = false;
                  this.alertHelper.showOkAlert('Success', `Document returned to the registry with the instruction to Put Away`, ()=> {
                    this.parent.reloadDashBoardVars();
                    this.router.navigate(['dashboard']);
                  })
                },
                error: e => {
                  this.actionInprogress = false;
                  this.alertHelper.showErrorAlert();
                }
              }
            )
          );
          break;
        }
      default:
        break;
    }
  }

  changeSubmissionTreamentStatusByRegistry(value) {
    const payload = {
      fileSubmissionId: this.submission_info.id,
      status: value
    };
    this.alertHelper.showYesNoAlert('Confirmation', `Are you sure you want to close treatment on this document and mark it as 'Completed'?`, ()=> {
      this.registryActionInprogress = true;
      this.sub.add(
        this.network.sendRequest(payload, NetworkService.CHANGE_FILE_STATUS).subscribe(
          {
            next: result => {
              this.registryActionInprogress = false;
              this.alertHelper.showOkAlert('Success', `Document has been succesfully closed and marked as 'Completed'`, ()=> {
                this.parent.reloadDashBoardVars();
                this.router.navigate(['dashboard']);
              })
            },
            error: e => {
              this.registryActionInprogress = false;
              this.alertHelper.showErrorAlert();
            }
          }
        )
      );
    });
  }

  rejectSubmissionByStaff() {
    const payload = {
      fileSubmissionId: this.submission_info.id
    };
    this.alertHelper.showYesNoAlert('Confirmation', `Are you sure you want to reject this document?`, ()=> {
      this.rejectActionInprogress = true;
      this.sub.add(
        this.network.sendRequest(payload, NetworkService.REJECT_SUBMISSION_BY_STAFF_STATUS).subscribe(
          {
            next: result => {
              this.rejectActionInprogress = false;
              this.alertHelper.showOkAlert('Success', `Document has been succesfully 'Rejected'`, ()=> {
                this.parent.reloadDashBoardVars();
                this.router.navigate(['dashboard']);
              })
            },
            error: e => {
              this.rejectActionInprogress = false;
              this.alertHelper.showErrorAlert();
            }
          }
        )
      );
    });
  }

  rowDblClickOpenFile(args){
    var sub_ref: string = args.rowData['CorrespondenceNo'];
    // let type = args.rowData['isArchiveDocument'];

    sub_ref = sub_ref.replace("#", "");
    var first_ref = this.submission_ref.replace("#", "");
    if(sub_ref){
        this.router.navigate(['treat-file', first_ref, 'folder-file', sub_ref]);
    }
  }
  loadingNewDepartmentUsers = false;

  fetchDepartmentUsers(department, index) {
    this.loadingNewDepartmentUsers = true;
    if (department) {
      this.sub.add(
        this.network.sendRequest({did: department.id}, NetworkService.GET_DEPARTMENT_USERS_PLAIN).subscribe((result) => {
          if (!result['error']) {
          this.loadingNewDepartmentUsers = false;
          this.department_users = JSON.parse(result['response']);
          this.getRecipients.controls[index].get('Recipient').patchValue(this.department_users[0].id);
          } else {
            this.loadingNewDepartmentUsers = false;
            this.alertHelper.showOkAlert('Error', 'Could not fetch users for the selected department, please choose another!')
          }
        })
      );
    }
  }

  fsDataBound(){
    //this.file_stats_grid.autoFitColumns(['copiedRecipients', 'SubmittedAt']);
  }

  checkEnteredKey(event) {
    this.getAllSelectedRecipients();
    const keyPressed = event.key;
    let lastNoneIndexOfAt = 0;
    let searchParam = '';
    const currentValue = this.route_message;
    const totalEntry = Array.from(currentValue);
    if (!this.showRecpsForMessage && event.keyCode === 64) {
      if (currentValue.length === 0) {
        lastNoneIndexOfAt = 0;
        this.showRecpsForMessage = true;
      } else if (totalEntry[currentValue.length - 1] === " " || totalEntry[currentValue.length - 1] === keyPressed) {
        this.showRecpsForMessage = true;
      }
    } else if ((!this.showRecpsForMessage && (event.keyCode === 8 || event.keyCode === 46)) || (this.showRecpsForMessage && (event.keyCode === 8 || event.keyCode === 46))) {
      // Split current value
      const allEnteredValue = currentValue.split(' ');
      const lastValueInArrayAbove = allEnteredValue[allEnteredValue.length - 1];
      const splittedLastValue = Array.from(lastValueInArrayAbove);
      if(splittedLastValue[0] === '@') {
        this.showRecpsForMessage = true;
      } else {
        this.showRecpsForMessage = false;
      }
    } else if (this.showRecpsForMessage && event.keyCode !== 64) {
      // Filter result
      if (keyPressed === ' ') {
        this.showRecpsForMessage = false;
      }
    }
  }

  recipientsForUse = [];

  getAllSelectedRecipients() {
    this.recipientsForUse = [];
    const allRecps = this.treatFileForm.get('Recipients').value;
    for (let index = 0; index < allRecps.length; index++) {
      const element = allRecps[index];
      const found = this.department_users.find(elv => elv.id === element.Recipient);
      this.recipientsForUse.push(found);
    }
  }

  appendSelectedUserNameToTextArea(value) {
    value += ' ';
    this.route_message += value;
    this.showRecpsForMessage = false;
    document.getElementById('routeMessage').focus();
  }
  returnToDashboard() {
    this.router.navigate(['/dashboard']);
  }
  getSelectedFile(files) {
    Object.entries(files).forEach(file => {
      this.checkSelectedFileBeforeAdding(file[1]);
    });
  }

  checkSelectedFileBeforeAdding(file) {
    if (this.selectedFiles.length === 0) {
      this.selectedFiles.push(file);
    } else if (this.selectedFiles.length < 5) {
      const found = this.selectedFiles.find(el => {
        return el.name == file.name;
      });
      found ?
      this.alertHelper.showOkAlert('Error', `File ${file.name} has already been selected!`) :
      this.selectedFiles.push(file);
    } else {
      return this.alertHelper.showOkAlert('Error', `Maximum number of allowed files reached! `);
    }
  }

  removeSelectedFile(file) {
    this.selectedFiles = this.selectedFiles.filter(entry => {
      return entry.name != file.name;
    });
  }

  openAddMoreFilesModal() {
    this.addMoreFilesShow = true;
  }

  closeAddMoreFilesModal() {
    this.addMoreFilesShow = false;
    this.selectedFiles = [];
  }

  uploadNewlySelectedFiles() {
    this.alertHelper.showYesNoAlert('Confirmation', 'Are you sure you want to add the selected files to this correspondence?', ()=> {
      const payload = {FileSubmissionId: this.submission_info.id};
      this.actionInprogress = true;
      this.sub.add(
        this.network.incomingMailsUploadNoKey(
          this.selectedFiles, NetworkService.ADD_FILES_TO_INCOMING_MAILS_SUBMISSION, payload).subscribe(
            response => {
              this.actionInprogress = false;
              if (!response['error']) {
                this.alertHelper.showOkAlert('Success', 'Selected file(s) added to the correspondence!', ()=> {
                  this.closeAddMoreFilesModal();
                  this.router.navigate(['dashboard']);
                });
              } else {
                this.alertHelper.showErrorAlert();
              }
            },
            error => {
              this.actionInprogress = false;
              this.alertHelper.showErrorAlert();
            }
          )
      );
    })
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

enum ViewerType {
  BaseTreater = "Base Treater",
  Treater = "Treater",
  SecondaryTreater = "Secondary Treater",
  TeamMember = "Team Member",
  BaseRecipient = "Base Recipient",
  Recipient = "Recipient",
  SecondaryRecipient = "Secondary Recipient",
  TeamMemberRecipient = "Team Recipient",
  ViewOnly = "View Only",
  CurrentTreater = "Current Treater",
  PreviousTreater = "Previous Treater"
}

export enum TreatmentStatus {
  Untreated = 7,
  Rejected = 10,
  Treated = 9,
  Pending = 8,
  Approved = 2,
  Disapproved = 3,
  AwaitingApproval = 1
}

class SubmissionInfo {
  id: number;
  ref: string = "";
  recipients: string = "";
  sdate: string = "";
  date_closed: string = "";
  sender_email: string = "";
  treatment_status: TreatmentStatus = TreatmentStatus.Untreated;
  subject: string = "";
  prev_coress: Array<string> = new Array();
  viewerType: ViewerType = null;
  files: Array<{
    fid: string;
    file_name: string;
    url: string;
    annotations?: string;
  }> = new Array();
  comments: Array<SubmissionComment> = new Array();
  level: number;
  current_treater: string = "";
  current_treater_status: number = 0;
  staff_treatment_status: number = 0;
  final_treater: string = "";
  file_urgency: number = 0;
  folders: Array<{id: number; folderName: string; submissionCount: number; folderId: string;}> = new Array();
}

export class SubmissionComment {
  comment_id: string = "";
  sender: string = "";
  recipient: string = "";
  comment: string = "";
  comment_date: string = "";
  sender_color: string = "";
  files: Array<{ fid: string; name: string; url: string }> = new Array();
}

class ShortUserInfo {
  id: number = null;
  name: string = "";
  email: String = "";
  helpful: string = "";
}

class TeamRouteeMember {
  id: number = null;
  name: string = "";
  email: string = "";
  status: string = "";
  responses?: Array<RouteeResponse>;
}

class RouteeResponse {
  message: string = "";
  files: Array<{ name: string; url: string }> = new Array();
}

enum RouteStatus {
  Untreated = "Pending acceptance",
  Rejected = "Pending response",
  Pending = "Rejected request",
  Completed = "Task complete",
  Cancelled = "Cancelled"
}

enum StaffTreatmentStatus {
  Untreated = 1,
  Pending = 2,
  Completed = 4,
  Rejected = 5,
  MoreInfo = 3,
  KIV = 6,
  PutAway = 7
}
