import {NgModule, ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPrintModule } from 'ngx-print';
import { MatDialogModule, MatProgressSpinnerModule, MatPaginatorModule } from '@angular/material';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs/';
import { GridAllModule, PagerModule, GridModule } from '@syncfusion/ej2-angular-grids';
import { CustomerNavbarComponent } from '../customer/sharedmodule/customer-navbar/customer-navbar.component';
import { RouteDocumentModalComponent } from '../customer/sharedmodule/route-document-modal/route-document-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalComponent } from './modal/modal.component';
import { ModalTriggerDirective } from '../directives/modal-trigger.directive';
import { SideDrawerTriggerDirective } from './drawer-trigger.directive';
import { AccountOpeningRouteCommentsComponent } from '../customer/account-creation-review/account-opening-route-comments/account-opening-route-comments.component';
import { DrawerComponent } from './side-drawer/side-drawer.component';
import { SideDrawerComponent } from '../customer/sharedmodule/side-drawer/side-drawer.component';
import { RbacAllowDirective } from '../directives/rbac-allow.directive';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxDocViewerModule } from 'ngx-doc-viewer';


@NgModule({
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        SelectDropDownModule,
        NgxPrintModule,
        MultiSelectModule,
        CommonModule,
        MultiSelectModule,
        TabsModule,
        PagerModule,
        MatPaginatorModule,
        BsDatepickerModule.forRoot(),
        NgSelectModule,
        GridModule,
        NgxDocViewerModule,
        PdfJsViewerModule,
    ],
    declarations: [
        AlertDialogComponent,
        CustomerNavbarComponent,
        DrawerComponent,
        RouteDocumentModalComponent,
        FileUploadComponent,
        ModalComponent,
        ModalTriggerDirective,
        DrawerComponent,
        SideDrawerTriggerDirective,
        AccountOpeningRouteCommentsComponent,
        SideDrawerComponent,
        RbacAllowDirective,
        FileViewerComponent
    ],
    exports: [
        CommonModule, MatDialogModule, MatProgressSpinnerModule, SelectDropDownModule, NgxPrintModule, MultiSelectModule,
        AlertDialogComponent,
        MultiSelectModule,
        TabsModule,
        GridModule,
        PagerModule,
        MatPaginatorModule,
        CustomerNavbarComponent,
        DrawerComponent,
        RouteDocumentModalComponent,
        BsDatepickerModule,
        FileUploadComponent,
        NgSelectModule,
        ModalComponent,
        ModalTriggerDirective,
        DrawerComponent,
        SideDrawerTriggerDirective,
        AccountOpeningRouteCommentsComponent,
        SideDrawerComponent,
        NgxDocViewerModule,
        RbacAllowDirective,
        FileViewerComponent,
        PdfJsViewerModule
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
          ngModule: SharedModule,
          providers: [ TabsetConfig ]
        };
      }
}