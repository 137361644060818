import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { PageSettingsModel, CommandModel, CommandClickEventArgs } from '@syncfusion/ej2-grids';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ILoadedEventArgs, ChartTheme, IPointRenderEventArgs } from '@syncfusion/ej2-charts';
import { Browser } from '@syncfusion/ej2-base';
import { DataManager, Query, ODataAdaptor, ReturnOption } from '@syncfusion/ej2-data';
import { Router } from '@angular/router';
import { NetworkService } from '../network/_services/network';
import moment from 'moment';
import { Helpers } from '../helpers';
import { AlertHelper } from '../helpers/alert-helper';
import { MatDialog } from '@angular/material';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../helpers/utils';
import { WindowRef } from '../helpers/windowref';
import { FileJacketService } from '../_services/file-jacket.service';
import { ECarbinetService } from '../_services/e-carbinet.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileManagerComponent implements OnInit {

  file_stats_loader = false;

  @ViewChild('archiveTaskgrid', {static: false}) public archive_task_grid: GridComponent;
  @ViewChild('recievedArchivegrid', {static: false}) public received_archive_grid: GridComponent;
  @ViewChild('userArchivegrid', {static: false}) public user_archive_grid: GridComponent;
  @ViewChild('deptArchivegrid', {static: false}) public dept_archive_grid: GridComponent;
  @ViewChild('mofiles', {static: false}) mofiles: ElementRef; //Mock Files Element Ref


  public pageSettings: PageSettingsModel;
  public toolbar: string[];

  file_stats: {count: number, today: number, data: Array<{TrackingNo: string, DocumentTitle: number, DocumentDate: string, Recepients: string, CreatedAt: string}>};
  // public toolbar: string[];

  files: any;
  departmentArchive: any;
  userArchive: any;
  recievedArchive: any;
  archiveTasks: any;
  showUploadInterface = false;
  hideViewInterface = false;
  selectedFiles: Array<File> = new Array();
  file_types_url = Helpers.file_types_urls;
  max_number_of_files: number = 5;
  max_files_size: number = 5;
  max_file_size_exceed: boolean = false;
  totFileSize: number = 0;
  totFileSizeString: string = '';
  alertHelper: AlertHelper;
  totalFIlesAdded = 0;
  startLoadingDept = false;
  maxRecipients = 3;
  showFileSUbmissionSuccessModal = false;



  constructor( private router: Router,
    private eCarbinetService: ECarbinetService, private cd: ChangeDetectorRef,
    private network: NetworkService, private dialog: MatDialog, private winRef: WindowRef, private formBuilder: FormBuilder, private _fileJacketService: FileJacketService) {
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    this.fetchDepartmentArchives();
    this.pageSettings = { pageSize: 5 };
    this.toolbar = ['Search'];
    this.createNewFolderForm();
  }

  closeFileSUbmissionSuccessModal() {
    this.showFileSUbmissionSuccessModal = false;
    this.showUploadInterface = false;
    this.hideViewInterface = false;
    this.totalFIlesAdded = 0;
    this.selectedFiles = [];
    this.emptySubmissionValues();
  }

  confidentialityLevels: Array<{}> = [
    {id: 4, name: 'Top Secret'},
    {id: 3, name: 'Secret'},
    {id: 2, name: 'Confidential'},
    {id: 1, name: 'Unclassified'}
  ];

  storagePats: Array<{}> = [
    {id: 1, name: 'Archive'},
    {id: 2, name: 'Route'}
  ];

  // Upload related functions starts here

  browseFiles(){
    this.startLoadingDept = true;
    var mock_files: HTMLInputElement = this.mofiles.nativeElement;
    mock_files.click();
    this.showUploadInterface = true;
    this.hideViewInterface = true;
    if(this.startLoadingDept && this.departments == null) {
      this.getDepartments();
    }
  }

  onFocusForToken() {
    this.showTokenInput = true;
    setTimeout(() => {
      var tokenInput = document.getElementById('reps-token');
      tokenInput.focus();
    }, 300);

  }

  onFocusForTokenKeys() {
    this.showTokenInput = true;
    setTimeout(() => {
      var tokenInput = document.getElementById('reps-token-keys');
      tokenInput.focus();
    }, 300);

  }

  cancelUpload() {
    this.showUploadInterface = false;
    this.hideViewInterface = false;
    this.totalFIlesAdded = 0;
    this.selectedFiles = [];
  }

  getDepartments() {
    this.network.sendRequest({}, NetworkService.GET_MINISTRY_DEPT)
    .subscribe((resp => {
      if(!resp['error']) {
        this.departments = JSON.parse(resp['response']);
        // var allDept = {name: 'All departments', id: 0};
        // this.departments.push(allDept);
      } else {
        return resp['error'];
      }
    }))
  }


  getPositions() {
    this.network.sendRequest({}, NetworkService.GET_USER_POSITIONS)
    .subscribe((resp => {
      if(!resp['error']) {
        this.positions = JSON.parse(resp['response']);
      } else {
        return resp['error'];
      }
    }))
  }



  getConfidentialityLevels() {
    this.network.sendRequest({}, NetworkService.GET_CONFY_LEVELS)
    .subscribe((resp => {
      if(!resp['error']) {
        this.confidentiality = JSON.parse(resp['response']);
      } else {
        return resp['error'];
      }
    }))
  }


  getStoragePatterns() {
    this.network.sendRequest({}, NetworkService.GET_STORAGE_PATTERNS)
    .subscribe((resp => {
      if(!resp['error']) {
        this.storagePattern = JSON.parse(resp['response']);
      } else {
        return resp['error'];
      }
    }))
  }

  mockFilesChange(){
    var mock_files: HTMLInputElement = this.mofiles.nativeElement;
    var fileError: boolean = false;
    if(mock_files.files){
      var total_file_count = this.selectedFiles.length + mock_files.files.length;
      this.totalFIlesAdded = total_file_count;
      if(total_file_count <= this.max_number_of_files){
        for(var i = 0; i < mock_files.files.length; i++){
          var findFile = this.selectedFiles.find((f)=>{
            return f.name == mock_files.files[i].name;
          });
          if(!findFile){
            if(this.file_types_url[mock_files.files[i].type]){
              this.selectedFiles.push(mock_files.files[i]);
              var file_size = +((mock_files.files[i].size/1024)/1024);
              this.totFileSize += file_size;
              this.totFileSizeString = this.totFileSize.toFixed(2) + 'MB';
            }else{
              this.totalFIlesAdded = this.totalFIlesAdded - 1;
              fileError = true;
            }
          }else{
            this.totalFIlesAdded = this.totalFIlesAdded - 1;
            fileError = true;
          }
        }
        if(fileError){
          this.alertHelper.showOkAlert('Skipped files', 'Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format')
        }
      }else{
        this.alertHelper.showOkAlert('Skipped files', 'You have reached the maximum number of files')
      }
    }
    mock_files.value = "";
  }

  removeFile(file: File){
    this.selectedFiles = this.selectedFiles.filter((f)=>{
      return f.name != file.name;
    })
    this.totalFIlesAdded -= 1;
    this.setFileSizes();
    if (this.totalFIlesAdded == 0) {
      this.showUploadInterface = false;
      this.hideViewInterface = false;
    }
  }

  setFileSizes(){
    var file_size = 0;
    this.selectedFiles.forEach((f)=>{
      file_size += ((f.size/1024)/1024);
    })
    this.totFileSize = file_size;
    this.totFileSizeString = this.totFileSize.toFixed(2) + 'MB';
  }



  ministries: {id: number, name: string, departments: {id: number, name: string, txFee: string}[], positions: {id: number, title: string }[]}[] = new Array();
  departments: {name: string; id: number;}[];
  departments2: {id: number, name: string, txFee: string}[];
  positions: {id: number, title: string }[];
  confidentiality: {id: number, name: string }[];
  storagePattern: {id: number, name: string }[];

  // selected_ministry: number;
  // selected_department: number;
  // selected_position: number;
  // selected_recepients = [];

  submission: {senderFullName: string, senderPhoneNumber: string, senderEmail: string, senderCompanyName: string, documentTitle: string, documentDate: string, documentDepartmentId: number, originalRecipient:any[], recipientDepartmentId: number, documentConfidentialityLevelId: number, storagePatternId: number, searchKeywords:any[], routeMessage: string, file: any[], corres:number} = {senderFullName: '', senderPhoneNumber: '', senderEmail: '', senderCompanyName: '', documentTitle: '', documentDate: '', documentDepartmentId: null, originalRecipient: [], recipientDepartmentId: null, documentConfidentialityLevelId: null, storagePatternId: null, searchKeywords:[], routeMessage: '', file: [], corres: 0};


  selected_ministry: {id: number, name: string, departments: {id: string, name: string}[]};
  selected_department: {id: number, name: string};
  selected_position: {id: number, title: string};
  selected_confy_level: {id: number, name: string};
  selected_storage: {id: number, name: string};

  uploader = false;


  selected_department_text: string = '';
  selected_position_text: string = '';
  selected_confy_text: string = '';
  selected_storage_text: string = '';
  selected_ministry_text: string = '';


  showTokenInput: boolean = false;
  tokenInputRecepientText: string = '';
  tokenInputSearchKeywordText: string = '';
  show_department = false;

  fetchDepartmentArchivesError = false;
  fetchUserArchivesError = false;
  fetchRecievedArchivesError = false;
  fetchArchivesTaskError = false;


  private isValidEmail(control: FormControl) {
    if (!Utils.isValidEmail(control.value)) {
        return {
            'isValidEmail@': true
        };
    }
    return null;
  }
  public validators = [this.isValidEmail];


  public errorMessages = {
    'isValidEmail@': 'The email is invalid'
};


public onRemoveTagKeys(event){
  console.log(event)
  if(this.submission.searchKeywords.length > 0){
    if(this.submission.searchKeywords.length > 1){
      this.tokenInputSearchKeywordText = this.submission.searchKeywords[0].value + ' + ' + (this.submission.searchKeywords.length - 1) + ' more.';
    }else{
      this.tokenInputSearchKeywordText = this.submission.searchKeywords[0].value;
    }
  }else{
    this.tokenInputSearchKeywordText = '';
  }
}

public onBlurKeys() {
  if(this.submission.searchKeywords.length > 0){
    if(this.submission.searchKeywords.length > 1){
      this.tokenInputSearchKeywordText = this.submission.searchKeywords[0].value + ' + ' + (this.submission.searchKeywords.length - 1) + ' more.';
    }else{
      this.tokenInputSearchKeywordText = this.submission.searchKeywords[0].value;
    }
    //this.showTokenInput = false;
  }
  // else{
  //   this.showTokenInput = false;
  // }
}




  departmentedSelected(){
    var dep = this.departments.find((d)=>{
      return d.id == this.submission.documentDepartmentId
    });
    this.selected_department_text = dep.name;
  }

  departmentedSelected2(event?){
    if(event && event.value){
      this.submission.documentDepartmentId = event.value.id;
      var dep = this.departments.find((d)=>{
        return d.id == this.submission.documentDepartmentId
      });
      this.selected_department = dep;
      this.selected_department_text = dep.name;
    }else{
      this.selected_department = null;
      this.submission.documentDepartmentId = null;
      this.selected_department_text = '';
      this.show_department = false;
      setTimeout(() => {
        this.show_department = true;
      }, 100);
    }
  }


  filePaths = [];
  fileNames = [];
  fileInfo = [];
  trackingNo = '';

  checkSubmissionDetails() {

    // Check for empty required fields

    if (this.submission.senderFullName == '') {
      this.alertHelper.showOkAlert('Error', 'Full Name Required!');
      return;
    }

    if (this.submission.senderPhoneNumber == '') {
      this.alertHelper.showOkAlert('Error', 'Phone number Required!');
      return;
    }

    if (this.submission.senderEmail == '') {
      this.alertHelper.showOkAlert('Error', 'Email Required!');
      return;
    }

    if (this.submission.documentTitle == '') {
      this.alertHelper.showOkAlert('Error', 'Document Title Required!');
      return;
    }

    if (this.submission.documentDate == '') {
      this.alertHelper.showOkAlert('Error', 'Document Date Required!');
      return;
    }

    if (this.submission.recipientDepartmentId == null) {
      this.alertHelper.showOkAlert('Error', 'Recipient Dept Required!');
      return;
    } else {
      this.submission.documentDepartmentId = this.submission.recipientDepartmentId;
    }

    if (this.submission.documentDepartmentId == null) {
      this.alertHelper.showOkAlert('Error', 'Document Department Required!');
      return;
    }

    if (this.submission.originalRecipient == null) {
      this.alertHelper.showOkAlert('Error', 'Recipient is Required!');
      return;
    }

    if (this.submission.documentConfidentialityLevelId == null) {
      this.alertHelper.showOkAlert('Error', 'Document Confidentiality Level Required!');
      return;
    }

    if (this.submission.storagePatternId == null) {
      this.alertHelper.showOkAlert('Error', 'Document Storage Pattern Required!');
      return;
    }

    // if (this.submission.storagePatternId == 2) {
    //   if (this.submission.routeMessage == '') {
    //     this.alertHelper.showOkAlert('Error', 'Route message needs to be entered if the file is to be archived and routed!');
    //     return;
    //   }
    // }

    if (this.submission.searchKeywords == []) {
      this.alertHelper.showOkAlert('Error', 'Search Keywords Required!');
      return;
    }

    if (this.submission.searchKeywords.length < 3) {
      this.alertHelper.showOkAlert('Error', 'At Least Three Keywords Required!');
      return;
    }

    if (this.selectedFolders.length < 1) {
      this.alertHelper.showOkAlert('Error', 'You need to specify a folder for this file!');
      return;
    }

    // Check for sender phone validity
    if (!Utils.isValidPhone(this.submission.senderPhoneNumber)) {
      this.alertHelper.showOkAlert('Error', 'Wrong Phone Format Entered!');
      return;
    }

    // Check for sender email validity
    if (!Utils.isValidEmail(this.submission.senderEmail)) {
      this.alertHelper.showOkAlert('Error', 'Wrong Email Format Entered!');
      return;
    }
    this.uploader = true;

    this.submission.file = this.selectedFiles;
    var files_arr = Array();
    this.submission.file.forEach( (file) => {
      files_arr.push(file);
    });
    var _sub: any =  JSON.stringify(this.submission);
    // tslint:disable-next-line: prefer-const
    var sub = JSON.parse(_sub);
    var arr_reps = Array();
    // tslint:disable-next-line: variable-name
    var arr_keys = Array();
    console.log(this.selected_users);
    this.selected_users.forEach((a) => {
      arr_reps.push(a.id);
    });
    // this.submission.originalRecipient.forEach((r) =>{
      // 
    // });
    this.submission.searchKeywords.forEach((r) =>{
      arr_keys.push(r.value);
    });
    sub.originalRecipient = arr_reps;
    sub.searchKeywords = arr_keys;
    var selFolders = Array();
    this.selectedFolders.forEach((a) => {
      selFolders.push(a.id.toString());
    });
    sub.FolderIds = selFolders;
    // var formData = new FormData();
    // // formData.append('files', this.selectedFiles);
    // this.selectedFiles.forEach((file) => {
    //   formData.append('file', file, file.name);
    // })
    // sub.files = formData;
    // debugger;
    if (this.submission.storagePatternId == 1) {
    this.network.archivesUploadNoKey(this.selectedFiles, NetworkService.SUBMIT_ARCHIVES, sub)
    .subscribe((resp) => {
      this.uploader = false;
      if(!resp['error']) {
        // debugger;
        console.log(resp['response']);
        var trackingNo = resp['response'];
        this.emptySubmissionValues();
        this.alertHelper.showOkAlert('Success', 'Archived Successfully with Tracking Number: '
        + trackingNo, () => {
        this.showUploadInterface = false;
        this.hideViewInterface = false;
        this.totalFIlesAdded = 0;
        this.selectedFiles = [];
        this.emptySubmissionValues();
        });
      } else {
        this.alertHelper.showOkAlert('Error',resp['response']);
      }
    });

  } else {
    this.network.archivesUploadNoKey(this.selectedFiles, NetworkService.INTERNAL_FILE_SUBMISSION, sub)
    .subscribe((resp) => {
      this.uploader = false;
      if(!resp['error']) {
        // debugger;
        var response = JSON.parse(resp['response']);
        this.trackingNo = response['correspondenceNo'];
        this.fileInfo = response['paths'];
        // this.fileInfo.forEach((element)=> {
        //   this.filePaths.push(element['filePath']);
        // });
        this.emptySubmissionValues();
        this.showFileSUbmissionSuccessModal = true;
        // this.alertHelper.showOkAlert('Success', 'Routed Successfully with Tracking Number: '
        // + this.trackingNo, () => {
        // this.showUploadInterface = false;
        // this.hideViewInterface = false;
        // this.totalFIlesAdded = 0;
        // this.selectedFiles = [];
        // this.emptySubmissionValues();
        // });
      } else {
        this.uploader = false;
        this.alertHelper.showOkAlert('Error', resp['response']);
      }
    }, (error) => {
      this.uploader = false;
      this.alertHelper.showErrorAlert();
    });

  }

    // console.log(formData);
  }

  sendToPrint(value: string) {
    var doc = window.open(value);
    doc.print();
  }


  emptySubmissionValues() {
    this.submission.senderFullName = '';
    this.submission.senderCompanyName = '';
    this.submission.senderEmail = '';
    this.submission.senderPhoneNumber = '';
    this.submission.documentTitle = '';
    this.submission.documentDate = '';
    this.submission.documentConfidentialityLevelId = null;
    this.submission.documentDepartmentId = null;
    this.submission.storagePatternId = null;
    this.submission.recipientDepartmentId = null;
    // this.submission.recipientPositionId = null;
    this.submission.routeMessage = '';
    this.submission.originalRecipient = [];
    this.submission.searchKeywords = [];
    this.selected_department_text = '';
    this.selected_position_text = '';
    this.selected_confy_text = '';
    this.selected_storage_text = '';
    this.tokenInputRecepientText = '';
    this.tokenInputSearchKeywordText = '';
    this.selected_users = [];
    this.selectedFolders = [];
  }

  fetchedUsers: any;
  department_users_load_async = false;
  fetchDepartmentUsers(departmentId) {
    this.department_users_load_async = true;
    this.network.sendRequest({did: departmentId}, NetworkService.GET_DEPARTMENT_USERS).subscribe((result) => {
      if (!result['error']) {
      this.department_users_load_async = false;
      this.fetchedUsers = JSON.parse(result['response']);
      // console.log(this.fetchedUsers);
      }
    });
  }

  fetchAllMinistryUsers() {
    this.department_users_load_async = true;
    this.network.sendRequest({}, NetworkService.GET_MINISTRY_WIDE_USERS).subscribe((result) => {
      if (!result['error']) {
      this.department_users_load_async = false;
      this.fetchedUsers = JSON.parse(result['response']);
      // console.log(this.fetchedUsers);
      }
    });
  }


  departmentedSelected1(){
    var dep = this.departments.find((d)=>{
      return d.id == this.submission.recipientDepartmentId
    });
    this.selected_department_text = dep.name;
  }

  departmentedSelected3(event?){
    if(event && event.value){
      this.submission.recipientDepartmentId = event.value.id;
      var dep = this.departments.find((d)=>{
        return d.id == this.submission.recipientDepartmentId
      });
      this.selected_department = dep;
      this.selected_department_text = dep.name;
      if (dep.id == 0) {
        this.fetchAllMinistryUsers();
        this.selected_users = [];
      } else {
        this.fetchDepartmentUsers(dep.id);
        this.selected_users = [];
      }
    }else{
      this.selected_department = null;
      this.submission.recipientDepartmentId = null;
      this.selected_department_text = '';
      this.show_department = false;
      this.selected_users = [];
      setTimeout(() => {
        this.show_department = true;
      }, 100);
    }
  }



  // postionCheck(){
  //   var dep = this.positions.find((d)=>{
  //     return d.id == this.submission.recipientPositionId;
  //   });
  //   this.selected_position_text = dep.title;
  // }
  selected_users: {id: any, name: string, email:string, fname: string, lname: string, color: string}[];

  userCheck2(event?){
    if(event && event.value){
      console.log(event.value.id);
      this.submission.originalRecipient.push(event.value.id);
      var dep = this.fetchedUsers.find((d)=>{
        return d.id == this.submission.originalRecipient
      });
      this.selected_position = dep;
      console.log(this.selected_users);

      // this.selected_position_text = dep.name;
    }else{
      this.selected_position = null;
      // this.submission.recipientPositionId = null;
      this.selected_position_text = '';
      this.show_department = false;
      setTimeout(() => {
        this.show_department = true;
      }, 100);
    }
  }

  selectedFolders: {id: number}[];

  folderCheck(event?){
    // if(event && event.value){
    //   this.selectedFolders.push(event.value.id);
    //   // var dep = this.fetchedUsers.find((d)=>{
    //   //   return d.id == this.submission.originalRecipient
    //   // });
    //   // this.selected_position = dep;

    //   // this.selected_position_text = dep.name;
    // }else{
    //   // this.selected_position = null;
    //   // this.submission.recipientPositionId = null;
    //   // this.selected_position_text = '';
    //   // this.show_department = false;
    //   // setTimeout(() => {
    //   //   this.show_department = true;
    //   // }, 100);
    // }
  }


  confyCheck2(event?){
    if(event && event.value){
      this.submission.documentConfidentialityLevelId = event.value.id;
      var dep = this.confidentiality.find((d)=>{
        return d.id == this.submission.documentConfidentialityLevelId;
      });
      this.selected_confy_level = dep;
      this.selected_confy_text = dep.name;
    }else{
      this.selected_confy_level = null;
      this.submission.documentConfidentialityLevelId = null;
      this.selected_confy_text = '';
      this.show_department = false;
      setTimeout(() => {
        this.show_department = true;
      }, 100);
    }
  }



  storageCheck2(event?){
    if(event && event.value){
      this.submission.storagePatternId = event.value.id;
      var dep = this.storagePattern.find((d)=>{
        return d.id == this.submission.storagePatternId;
      });
      this.selected_storage = dep;
      this.selected_storage_text = dep.name;
    }else{
      this.selected_storage = null;
      this.submission.storagePatternId = null;
      this.selected_storage_text = '';
      this.show_department = false;
      setTimeout(() => {
        this.show_department = true;
      }, 100);
    }
  }


  config = {
    displayKey:'name', //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select department', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: this.ministries.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  config2 = {
    displayKey:'name', //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select recipients', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: this.ministries.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  config3 = {
    displayKey:'folderName', //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select a folder for this file', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: this.ministries.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'folderName' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  // Upload related functions ends here

  rowDblClickOpenFile(args){
    var sub_ref: string = args.rowData['FilesTrackingNo'];
    sub_ref = sub_ref.replace('#', '');
    if(sub_ref){
      this.router.navigate(['/view-archive', sub_ref]);
    }
  }

  fsDataBound(){
    //this.file_stats_grid.autoFitColumns(['copiedRecipients', 'SubmittedAt']);
  }

  // or_rec: string = "";

  fetchDepartmentArchives() {
    this.file_stats_loader = true;
    this.network.sendRequest({}, NetworkService.GET_DEPT_ARCHIVE_LIST).subscribe((res) => {
      if(!res['error']){
        this.files = JSON.parse(res['response'])
        this.departmentArchive = this.files['data'];
        //debugger;
        // // this.or_rec = this.mainData["OriginalRecipient"]["FullName"];
        // //let name = this.mainData["FullName"];
        for (let index = 0; index < this.departmentArchive.length; index++) {
          // const element = array[0];
          // this.mainData[index]["OriginalRecipient"] = JSON.parse(this.mainData[index]["OriginalRecipient"]);
          this.departmentArchive[index]['DocumentDate'] = moment(this.departmentArchive[index]['DocumentDate']).format('Do MMMM YYYY, h:mm a');
          this.departmentArchive[index]['CreatedAt'] = moment(this.departmentArchive[index]['CreatedAt']).format('Do MMMM YYYY, h:mm a');

        }
      }else{
        this.fetchDepartmentArchivesError = true;
        setTimeout(() => {
        this.fetchDepartmentArchivesError = false;
          this.fetchDepartmentArchives();
        },10000);
      }
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    }, (error)=>{
      this.file_stats_loader = false;
      this.fetchDepartmentArchivesError = true;
      setTimeout(() => {
        this.fetchDepartmentArchivesError = false;
        this.fetchDepartmentArchives();
      },10000);
      // this.reloadFileStatsDataTable();
    })
  }

  // this.fetchUserArchives();



  fetchUserArchives() {
    this.file_stats_loader = true;
    this.network.sendRequest({}, NetworkService.GET_USER_ARCHIVE_LIST).subscribe((res) => {
      if(!res['error']){
        this.files = JSON.parse(res['response'])
        this.userArchive = this.files['data'];
        //debugger;
        // // this.or_rec = this.mainData["OriginalRecipient"]["FullName"];
        // //let name = this.mainData["FullName"];
        for (let index = 0; index < this.userArchive.length; index++) {
          // const element = array[0];
          // this.mainData[index]["OriginalRecipient"] = JSON.parse(this.mainData[index]["OriginalRecipient"]);
          this.userArchive[index]['DocumentDate'] = moment(this.userArchive[index]['DocumentDate']).format('Do MMMM YYYY, h:mm a');
          this.userArchive[index]['CreatedAt'] = moment(this.userArchive[index]['CreatedAt']).format('Do MMMM YYYY, h:mm a');

        }
        //let
        // console.log(this.mainData);
        // this.mainData.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    }, (error)=>{
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    })
  }



  fetchRecievedArchives() {
    this.file_stats_loader = true;
    this.network.sendRequest({}, NetworkService.GET_RECIEVED_ARCHIVE_LIST).subscribe((res) => {
      if(!res['error']){
        this.files = JSON.parse(res['response'])
        this.recievedArchive = this.files['data'];
        //debugger;
        // // this.or_rec = this.mainData["OriginalRecipient"]["FullName"];
        // //let name = this.mainData["FullName"];
        for (let index = 0; index < this.recievedArchive.length; index++) {
          // const element = array[0];
          // this.mainData[index]["OriginalRecipient"] = JSON.parse(this.mainData[index]["OriginalRecipient"]);
          this.recievedArchive[index]['DocumentDate'] = moment(this.recievedArchive[index]['DocumentDate']).format('Do MMMM YYYY, h:mm a');
          this.recievedArchive[index]['CreatedAt'] = moment(this.recievedArchive[index]['CreatedAt']).format('Do MMMM YYYY, h:mm a');

        }
        //let
        // console.log(this.mainData);
        // this.mainData.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    }, (error)=>{
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    })
  }




  fetchArchivedTasks() {
    this.file_stats_loader = true;
    this.network.sendRequest({}, NetworkService.GET_ARCHIVE_TASKS_LIST).subscribe((res) => {
      if(!res['error']){
        this.files = JSON.parse(res['response'])
        this.archiveTasks = this.files['data'];
        //debugger;
        // // this.or_rec = this.mainData["OriginalRecipient"]["FullName"];
        // //let name = this.mainData["FullName"];
        for (let index = 0; index < this.archiveTasks.length; index++) {
          // const element = array[0];
          // this.mainData[index]["OriginalRecipient"] = JSON.parse(this.mainData[index]["OriginalRecipient"]);
          this.archiveTasks[index]['CreatedAt'] = moment(this.archiveTasks[index]['CreatedAt']).format('Do MMMM YYYY, h:mm a');

        }
        //let
        // console.log(this.mainData);
        // this.mainData.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    }, (error)=>{
      this.file_stats_loader = false;
      // this.reloadFileStatsDataTable();
    })
  }

  routeArchive(file: any) {
    console.log(file);
  }


  scanDocument(){
    this.winRef.nativeWindow.scanDocument();
  }

  @HostListener('window:scandone', ['$event'])
  listenForScan(event: CustomEvent){
    //console.log(event)
    var successful = event.detail.successful;
    var mesg = event.detail.mesg;
    var response = event.detail.response;
    if(!successful) { // On error
      //console.error('Failed: ' + mesg);
      this.alertHelper.showOkAlert("Error", mesg);
      return;
    }

    if(successful && mesg != null && mesg.toLowerCase().indexOf('user cancel') >= 0) { // User canceled.
        //console.info('User canceled');
        return;
    }

    var scannedImages = this.winRef.nativeWindow.scanner.getScannedImages(response, true, false); // returns an array of ScannedImage
    for(var i = 0; (scannedImages instanceof Array) && i < scannedImages.length; i++) {
        var scannedImage = scannedImages[i];
        this.processScannedImage(scannedImage);
    }
  }

  processScannedImage(scannedImage) {
    var __this = this;
    var re = /(-)+/gi;
    Utils.urltoFile(scannedImage.src, 'Scan_' + Utils.createGUID().replace(re, '') + '.pdf', scannedImage.mimeType)
        .then(function(file){
            //console.log(file);
            __this.selectedFiles.push(file);
            var total_file_count = this.selectedFiles.length;
            this.totalFIlesAdded = total_file_count;
            __this.showUploadInterface = true;
            __this.hideViewInterface = true;
            var fileError: boolean = false;
            if(total_file_count <= this.max_number_of_files){
                var findFile = this.selectedFiles.find((f)=>{
                  return f.name == file.name;
                });
                if(!findFile){
                  if(this.file_types_url[file.type]){
                    // this.selectedFiles.push(mock_files.files[i]);
                    var file_size = +((file.size/1024)/1024);
                    this.totFileSize += file_size;
                    this.totFileSizeString = this.totFileSize.toFixed(2) + 'MB';
                  }else{
                    this.totalFIlesAdded = this.totalFIlesAdded - 1;
                    fileError = true;
                  }
                }else{
                  this.totalFIlesAdded = this.totalFIlesAdded - 1;
                  fileError = true;
                }
              if(fileError){
                this.alertHelper.showOkAlert('Skipped files', 'Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format')
              }
            }else{
              this.alertHelper.showOkAlert('Skipped files', 'You have reached the maximum number of files')
            }
        })
  }

  // Everything Folder starts here...

  showCreateFolderModal = false;
  newFolder: {FolderName: string, DepartmentId: number} = {FolderName: '', DepartmentId: 0};
  newFolderForm: FormGroup;
  qrPreviewUrl: any;
  createFolderSpinner = false;
  showGeneratedQRCode = false;
  imageToPrint = '../../assets/img/avatar.jpg';
  allAvailableFolders: {id: number, folderName: string}[] = [];
  allAvailableArchivedFolders: {id: number, folderName: string}[] = [];
  allAvailableActiveFolders: {id: number, folderName: string}[] = [];

  createNewFolderForm() {
    this.newFolderForm = this.formBuilder.group({
      FolderName: ['', Validators.required],
      DepartmentId: [null, Validators.required]
    })
  }

  closeCreateFolderModal() {
    this.showCreateFolderModal = false;
  }

  checkSelectedFolderOption($event) {
    if($event.target.value == "new") {
      this.showCreateFolderModal = true;
    }
  }

  openNewFolderModal() {
    this.showCreateFolderModal = true;
  }

  createNewFolder() {
    this.createFolderSpinner = true;
    this.newFolder = this.newFolderForm.value;
    console.log(this.newFolder);
    this.network.sendFileJacketRequest(this.newFolder, NetworkService.CREATE_NEW_FOLDER).subscribe(response => {
      if(!response['error']) {
        this.createFolderSpinner = false;
        let resp = response;
        let idGotten = resp['Id'];
        // this.allAvailableFolders = this.someFn({id: idGotten, folderName: this.newFolder.FolderName});
        this.allAvailableFolders = [...this.allAvailableFolders, {id: idGotten, folderName: this.newFolder.FolderName}];
        console.log(this.allAvailableFolders);
        this.generateQRCode(resp['QRCode']);
        // this.showGeneratedQRCode = true;
        this.alertHelper.showOkAlert('Success', 'Folder created successfully.', ()=> {
          this.showCreateFolderModal = false;
          this.createNewFolderForm();
          // this.cd.detectChanges();
          this.getStoragePatternFolders(this.submission.storagePatternId);
        });
      } else {
        this.createFolderSpinner = false;
        this.alertHelper.showOkAlert('Error', response['response']);
      }
    }, error => {
      this.createFolderSpinner = false;
      this.alertHelper.showErrorAlert();
    })
  }

  someFn(obj: any): any[] {
    this.allAvailableFolders.push(obj);
    return [...new Set(this.allAvailableFolders)];
  }

  fetchAllFolders(folderStatus) {
    if (folderStatus === 'Archived') {
      this.eCarbinetService.getAllEcarbinetFoldersByStatus({statusId: folderStatus}).subscribe(
        {
          next: (response: Array<any>) => {
            console.log(response);
            this.allAvailableFolders = response;
          },
          error: e => {
            console.log(e);
          }
        }
      );
    } else {
      this.eCarbinetService.getAllEcarbinetFoldersByStatus({statusId: folderStatus}).subscribe(
        {
          next: (response: Array<any>) => {
            console.log(response);
            this.allAvailableFolders = response;
          },
          error: e => {
            console.log(e);
          }
        }
      );
    }
  }

  getStoragePatternFolders(value) {
    value == 1 ? this.fetchAllFolders('Archived') : this.fetchAllFolders('Active');
  }

  generateQRCode(base64Data) { 
        this.qrPreviewUrl = base64Data;
  }

}
