import { InternalDocumentComponent } from './internal-document/internal-document.component';
import { MemosComponent } from './memos/memos.component';
import { ChatpageComponent } from './chatpage/chatpage.component';
import { TreatFileComponent } from './treat-file/treat-file.component';
import { RewardsComponent } from './rewards/rewards.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SignUpDoneComponent } from './sign-up-done/sign-up-done.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { GuardService } from './network/_guards/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ViewArchiveComponent } from './view-archive/view-archive.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { LeaveMgtComponent } from './leave-mgt/leave-mgt.component';
import { ViewLeaveComponent } from './view-leave/view-leave.component';
import { InterMinistryModule } from './inter-ministry/inter-ministry.module';
import { PreviewSubmissionComponent } from './preview-submission/preview-submission.component';
import { NewAccountCorporateReviewComponent } from './new-account-corporate-review/new-account-corporate-review.component';


const routes: Routes = [
  {path: 'login', canActivate: [GuardService], component: SignInComponent},
  {path: 'register', canActivate: [GuardService], component: SignUpComponent},
  {path: 'register/done', canActivate: [GuardService], component: SignUpDoneComponent},
  {path: 'verify_email', canActivate: [GuardService], component: VerifyEmailComponent},
  {path: 'reset-password', canActivate: [GuardService], component: ResetPasswordComponent},
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
      path: '',
      // canActivate: [GuardService],
      component: DashboardLayoutComponent,
      children: [
        { path: 'dashboard', canActivate: [GuardService], component: DashboardComponent,  data:{title: 'Dashboard'}},
        { path: 'rewards', canActivate: [GuardService], component: RewardsComponent,  data:{title: 'Rewards'}},
        { path: 'treat-file/:ref', canActivate: [GuardService], component: TreatFileComponent,  data:{title: 'Treat File'}},
        { path: 'treat-file/:ref/folder-file/:ref', canActivate: [GuardService], component: PreviewSubmissionComponent,  data:{title: 'Treat File'}},
        { path: 'document/:type/:ref', canActivate: [GuardService], component: InternalDocumentComponent,  data:{title: 'Document'}},
        { path: 'account', canActivate: [GuardService], component: ProfileComponent,  data:{title: 'Account'}},
        { path: 'change-password', canActivate: [GuardService], component: ChangePasswordComponent,  data:{title: 'Change Password'}},
        { path: 'chat', canActivate: [GuardService], component: ChatpageComponent,  data:{title: 'Chat'}},
        { path: 'file-manager', canActivate: [GuardService], component: FileManagerComponent,  data:{title: 'File Manager'}},
        { path: 'documents', canActivate: [GuardService], component: MemosComponent,  data:{title: 'Memos'}},
        {path: 'view-archive/:FilesTrackingNo', canActivate: [GuardService], component: ViewArchiveComponent},
        {path: 'view-leave/:LeaveRefNumber', canActivate: [GuardService], component: ViewLeaveComponent},
        { path: 'leave-mgt', canActivate: [GuardService], component: LeaveMgtComponent,  data:{title: 'Leave'}},
        { path: 'inter-ministry', loadChildren: './inter-ministry/inter-ministry.module#InterMinistryModule' },
        { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
        { path: 'review', loadChildren: () => import('./customer/account-creation-review/account-creation-review.module').then(m => m.AccountCreationReviewModule) },
        { path: 'e-carbinet', loadChildren: () => import('./e-carbinet/e-carbinet.module').then(m => m.ECarbinetModule) },
        { path: 'div-e-carbinet', loadChildren: () => import('./div-e-carbinet/div-e-carbinet.module').then(m => m.DivECarbinetModule) },
        { path: 'incoming-mails', loadChildren: () => import('./upload-incoming-mail/upload-incoming-mail.module').then(m => m.UploadIncomingMailModule) },
        { path: 'upload-incoming-mail', loadChildren: () => import('./registry-incoming-mail/registry-incoming-mail.module').then(m => m.RegistryIncomingMailModule) },
        { path: 'complete-submission-process/:mailId', loadChildren: () => import('./complete-submission-process/complete-submission-process.module').then(m => m.CompleteSubmissionProcessModule) },
      ]
  }, 
  // { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
  { path: 'accounts/individual', loadChildren: () => import('./new-account-individual/new-account-individual.module').then(m => m.NewAccountIndividualModule) },
  { path: 'accounts/corporate', loadChildren: () => import('./new-account-corporate/new-account-corporate.module').then(m => m.NewAccountCorporateModule) },
  
  { path: 'corporate-review', component: NewAccountCorporateReviewComponent,},
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
