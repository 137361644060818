import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { NetworkService } from '../network/_services/network';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncomingMailService {

  constructor(
    private http: HttpClient
  ) { }

  getAllClerkRelatedIncomingMail(): Observable<Array<IncomingMailsForClerkView>> {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.get<Array<IncomingMailsForClerkView>>(`${environment.host}${NetworkService.GETALLINCOMINGMAIL}`, {headers});
  }

  getAllExternalIncomingMail(): Observable<Array<IncomingMailsForClerkView>> {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.get<Array<IncomingMailsForClerkView>>(`${environment.host}${NetworkService.GETALLEXTERNALINCOMINGMAIL}`, {headers});
  }

  getAllRegistryIncomingMail(): Observable<Array<IncomingMailsForClerkView>>  {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.get<Array<IncomingMailsForRegistryView>>
            (`${environment.host}${NetworkService.GET_ALL_INCOMING_MAILS_SUBMISSION}`, {headers});
  }

  getSingleRegistryIncomingMail(id: string): Observable<IncomingMailsForClerkView>  {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    const params = new HttpParams().set('FileSubmissionId', id);
    return  this.http.get<IncomingMailsForRegistryView>
            (`${environment.host}${NetworkService.GET_SINGLE_INCOMING_MAILS_SUBMISSION}`, {headers, params});
  }

  getAllStaffUntreatedIncomingMail() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.post
            (`${environment.host}${NetworkService.GET_ALL_STAFF_UNTREATED_INCOMING_MAILS}`, {}, {headers});
  }

  getAllStaffPendingIncomingMail() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.post
            (`${environment.host}${NetworkService.GET_ALL_STAFF_PENDING_INCOMING_MAILS}`, {}, {headers});
  }

  getAllStaffClosedIncomingMail() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.post
            (`${environment.host}${NetworkService.GET_ALL_STAFF_CLOSED_INCOMING_MAILS}`, {}, {headers});
  }

  getAllStaffTreatedIncomingMail() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.post
            (`${environment.host}${NetworkService.GET_ALL_STAFF_TREATED_INCOMING_MAILS}`, {}, {headers});
  }

  changeStaffFileStatus(payload) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.post
            (`${environment.base_url}${NetworkService.CHANGE_STAFF_FILE_STATUS}`, payload, {headers});
  }

  changeFileStatus(payload) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return  this.http.post
            (`${environment.base_url}${NetworkService.CHANGE_FILE_STATUS}`, payload, {headers});
  }
}


export interface IncomingMailsForClerkView {
  CompanyName: string;
  CorrespondenceNo: string;
  CreatedAt: string;
  DateCreated: string;
  Email: string;
  FileSubmissionId: number;
  PhoneNo: string;
  Subject: string;
  TreatmentStatusId: number;
  acknowledgeCopies: Array<{
    FileName: string,
    WatermarkedFilePath: string
  }>;
}

export interface IncomingMailsForRegistryView {
  CompanyName: string;
  CorrespondenceNo: string;
  CreatedAt: string;
  DateCreated: string;
  Email: string;
  FileSubmissionId: number;
  PhoneNo: string;
  Subject: string;
  TreatmentStatusId: number;
  acknowledgeCopies: Array<{
    FileName: string,
    WatermarkedFilePath: string
  }>;
}