import { environment } from './../../../environments/environment';


import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user';

/*
  Generated class for the AuthProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthService {

  url = environment.auth_url;
  constructor(public http: HttpClient) {
  }

  login(user) {
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(user);
    let fi = this.jsonToURLEncoded(user);
    //return this.http.post(this.url + "user_login",fi,{headers});
    return this.http.post(this.url + "sign-in",fi,{headers});
  }

  signup(user) {
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(user);
    let fi = this.jsonToURLEncoded(user);
    //return this.http.post(this.url + "user_signup",fi,{headers});
    return this.http.post(this.url + "sign-up",fi,{headers});
  }

  docSignUp(fileItems:Array<{key:string, file: File}>, extraData?:object){
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
        .set("Content-Type", "multipart/form-data");
    fileItems.forEach(element => {
        formData.append(element.key, element.file, element.file.name);
    });
    
    if (extraData) {
      for(let key in extraData){
        formData.append(key, extraData[key])
      }
    }
    const req = new HttpRequest('POST', this.url + "sign-up", formData, {
      headers: headers,
      reportProgress: true // for progress data
    });
    return this.http.request(req);
  }

  forgotpass(user) {
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(user);
    let fi = this.jsonToURLEncoded(user);

    return this.http.post(this.url + "forgot-password",fi,{headers});
  }

  activateAccount(token_email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(token_email);
    let fi = this.jsonToURLEncoded(token_email);

    return this.http.post(this.url + "activate-account",fi,{headers});
  }

  resendToken(email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(email);
    let fi = this.jsonToURLEncoded(email);

    return this.http.post(this.url + "resend-token",fi,{headers});
  }

  altEmail(email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(email);
    let fi = this.jsonToURLEncoded(email);

    return this.http.post(this.url + "alt-email",fi,{headers});
  }

  passRecoveryAlt(email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(email);
    let fi = this.jsonToURLEncoded(email);

    return this.http.post(this.url + "password-recovery-alt",fi,{headers});
  }
  passRecovery(email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(email);
    let fi = this.jsonToURLEncoded(email);

    return this.http.post(this.url + "password-recovery",fi,{headers});
  }
  passRecoveryToken(email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(email);
    let fi = this.jsonToURLEncoded(email);

    return this.http.post(this.url + "password-recovery-token",fi,{headers});
  }
  passReset(email){
    let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");
    let body = JSON.stringify(email);
    let fi = this.jsonToURLEncoded(email);

    return this.http.post(this.url + "password-reset",fi,{headers});
  }
  
  
  private jsonToURLEncoded(jsonString){
    return Object.keys(jsonString).map(function(key){
      return encodeURIComponent(key) + '=' + encodeURIComponent(jsonString[key]);
    }).join('&');
  }

}
