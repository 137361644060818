import { Router } from '@angular/router';
import { AuthService } from './../network/_services/auth.service';
import { NetworkService } from './../network/_services/network';
import { MatDialog } from '@angular/material';
import { AlertHelper } from './../helpers/alert-helper';
import { Role } from './../models/role';
import { Component, OnInit } from '@angular/core';
import { CacheRequest } from '../models/cache';
import { Utils } from '../helpers/utils';
import { Helpers } from '../helpers';
import countrys from "../../assets/data/countries.json";


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  currentYear: string = new Date().getFullYear().toString();
  currentPage: number = 0;
  stepPage: number = 0;
  category: Role = Role.GStaff;
  alertHelper: AlertHelper;
  frm_err: boolean = false;
  frm_err_text: string = "";

  usr_data = {email: "",PersonalEmail: "", password: "", cpass: "", FirstName: "", LastName: "", MiddleName: "", PhoneNo1: "", PhoneNo2: "", CompanyName: "", HouseNo: "", Street: "", CityTown: "", StateId: null, CountryId: null, LandMark: "", Website: "", AlternateEmail: "", UserAccountType: ""};

  // signup_data = {countries: [{id: 1, name: "Nigeria", states: [{id: 1, name: "Abuja"}]}, {id: 2, name: "Ghana", states: [{id: 1, name: "Abuja"}]}]};
  signup_data: {countries: {id: number, name: string, states: {id: number, name: string}[]}[]} = {countries: [{id: 1, name: "Nigeria", states: [{id: 1, name: "Abuja"}]}, {id: 2, name: "Ghana", states: [{id: 1, name: "Abuja"}]}]};

  states: {id: number, name: string}[];

  show_signup_async: boolean = false;

  constructor(private dialog: MatDialog, private network: NetworkService, public auth: AuthService, private router: Router) { 
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    Utils.getCountries().then((countries)=>{
      this.signup_data.countries = countries;
    })
  }

  preloadData(){
    this.network.sendRequest({}, NetworkService.DASHBOARD, CacheRequest.Cache).subscribe((res)=>{
      if(!res['error']){
        this.signup_data = JSON.parse(res['response']);
      }else{
      }
    }, (error)=>{
    })
  }

  countrySelected(){
    var sel_country = this.signup_data.countries.find((c) =>{
      return c.id == this.usr_data.CountryId;
    })
    if(sel_country){
      this.states = sel_country.states;
    }
  }

  nextStep(){
    this.frm_err = false;
    if(this.stepPage >= 2){
      var errorInSteps = this.validateAllSteps();
      if(typeof errorInSteps == "string"){
        this.frm_err = true;
        this.frm_err_text = errorInSteps;
      }else{
        //Submit Form
        this.usr_data.UserAccountType = this.category.toString();
        console.log(this.usr_data);
        this.show_signup_async = true;
        this.auth.signup(this.usr_data).subscribe((result) =>{
          if(!result['error']){
            var token_email = {email: this.usr_data.email, tokenExpired: false};
            localStorage.setItem(Helpers.VERIFY_EMAIL_DETAIL, JSON.stringify(token_email));
            this.router.navigate(['/verify_email']);
          }else{
            this.show_signup_async = false;
            this.alertHelper.showOkAlert("Signup Error", result['response']);
          }
        }, (error) =>{
          this.show_signup_async = false;
          this.alertHelper.showErrorAlert();
        });
      }
    }else{
      var errorInValidate
      if(this.stepPage == 0){
        errorInValidate = this.validateStepOne();
      }else if(this.stepPage == 1){
        errorInValidate = this.validateStepTwo();
      }
      if(!errorInValidate){
        this.stepPage += 1;
      }else{
        this.frm_err = true;
        this.frm_err_text = errorInValidate;
      }
    }
  }

  validateStepOne(): string | boolean{
    if(this.usr_data.FirstName.trim() == ""){
      return "Please enter your first name";
    }else if(this.usr_data.MiddleName.trim() == ""){
      return "Please enter your niddle name";
    }else if(this.usr_data.LastName.trim() == ""){
      return "Please enter your last name";
    }else if(this.usr_data.PhoneNo1.trim() == ""){
      return "Please enter your mobile number";
    }else if(this.usr_data.email.trim() == ""){
      return "Please enter your email address";
    }else if(!Utils.isValidEmail(this.usr_data.email.trim())){
      return "Please enter a valid email address";
    }else if(this.usr_data.password.trim() == ""){
      return "Enter password";
    }else if(this.usr_data.cpass.trim() == ""){
      return "Enter confrim password";
    }else if(this.usr_data.password.trim().length < 8 || this.usr_data.password.trim().length > 16 ){
      return "Your password must be 8-16 characters long";
    }else if(this.usr_data.password.trim() != this.usr_data.cpass.trim()){
      return "Password and confirm password does not match";
    }
    return false;
  }
  validateStepTwo(): string | boolean{
    if(this.usr_data.CountryId == null){
      return "Please select your country";
    }else if(this.usr_data.StateId == null){
      return "Please select your state";
    }else if(this.usr_data.HouseNo.trim() == ""){
      return "Please enter your house number";
    }else if(this.usr_data.Street.trim() == ""){
      return "Please enter your street";
    }else if(this.usr_data.CityTown.trim() == ""){
      return "Please enter your town";
    }
    return false;
  }
  validateStepThree(): string | boolean{
    if(this.usr_data.AlternateEmail.trim() == ""){
      return "Please enter an alternate email";
    }else if(!Utils.isValidEmail(this.usr_data.AlternateEmail.trim())){
      return "Please enter a valid alternate email address";
    }
    return false;
  }
  validateAllSteps(){
    var stepOne = this.validateStepOne();
    var stepTwo = this.validateStepTwo();
    var stepThree = this.validateStepThree();

    if(typeof stepOne == "string"){
      return stepOne;
    }
    if(typeof stepTwo == "string"){
      return stepTwo;
    }
    if(typeof stepThree == "string"){
      return stepThree;
    }
    return false;
  }

  prevStep(){
    if(this.stepPage > 0){
      this.stepPage -= 1;
    }
  }

  getPercent(withPercent?){
    var add = "";
    if(withPercent){
      add = "%";
    }
    return (((this.stepPage + 1)/ 3) * 100).toFixed(2) + add;
  }

  categoryChange(ev){
    // console.log(ev.target.value);
    this.category = ev.target.value;
    console.log(this.category);
  }

  continueToSignup(){
    if(this.category != Role.None){
      this.currentPage = 1;
    }else{
      this.alertHelper.showOkAlert("No category", "Please specify a category");
    }
  }

  selectCategory(){
    this.currentPage = 0;
  }

}
