import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 30000;
@Injectable()
export class RequestCache  {

  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    var body = req.body ? JSON.stringify(req.body) : "";
    const url = req.urlWithParams + body;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    if(cached.req.body !== req.body){
      return undefined;
    }

    //if(req.headers.get())

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired = isExpired ? 'expired ' : '';
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    var body = req.body ? JSON.stringify(req.body) : "";
    const url = req.urlWithParams + body;
    const entry = { req, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  remove(req: HttpRequest<any>): void{
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (cached) {
      this.cache.delete(url)
    }
  }
}