import { timeout } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
export class AlertHelper {
  constructor(private dialog: MatDialog) {

  }

  showErrorAlert() {
    this.dialog.open(AlertDialogComponent, {
      data: {
        title: "Error",
        text: "Sorry an unexpected error occurred.",
        buttons: [
          {
            text: "Ok",
            class: 'btn btn-primary'
          }
        ]
      }
    })
  }
  showOkAlert(title: string, message: string, callback?: () => any, timeout?: number) {
    this.dialog.open(AlertDialogComponent, {
      data: {
        title: title,
        text: message,
        buttons: [
          {
            text: "Ok",
            callback: () => {callback()},
            class: 'btn btn-primary'
          }
        ],
        timeout: timeout,
        timeoutCallBack: () => {callback()}
      }
    })
  }

  showYesNoAlert(title: string, message: string, callbackk?: () => any, noCallBack?: () => any) {
    this.dialog.open(AlertDialogComponent, {
      data: {
        title: title,
        text: message,
        buttons: [
          {
            text: 'Yes',
            callback: () => { callbackk() },
            class: 'btn btn-primary'
          },
          {
            text: "No",
            callback: () => { noCallBack() },
            class: 'btn btn-danger'
          }
        ]
      }
    })
  }
}