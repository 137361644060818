
import countrys from "../../assets/data/countries.json";

export class Utils {
    static checkFileExtensions(filename: string, exts: Array<string>) {
        var found = false;
        exts.forEach(ext => {
            var fileExtension = "";
            if (filename.lastIndexOf(".") > 0) {
                fileExtension = filename.substring(filename.lastIndexOf(".") + 1, filename.length);
            }
            if (fileExtension.toLowerCase() == ext) {
                found = true;
                return;
            }
        });
        return found;
    }

    static isValidPhone(phone: string) {
      const re1 = '^[0](((8)(0|1))|((7)(0))|((9)(0)))\\d{8}$';	// Phone

      const p = new RegExp(re1);
      const m = p.test(phone);
      return m;
    }


    static checkFileExtension(filename: string, ext: string) {
        var fileExtension = "";
        if (filename.lastIndexOf(".") > 0) {
            fileExtension = filename.substring(filename.lastIndexOf(".") + 1, filename.length);
        }
        if (fileExtension.toLowerCase() == ext) {
            return true;
        } else {
            return false;
        }
    }

    static isValidEmail(email:string){
        var re1='([\\w-+]+(?:\\.[\\w-+]+)*@(?:[\\w-]+\\.)+[a-zA-Z]{2,7})';	// Email

        var p = new RegExp(re1,"i");
        var m = p.exec(email);
        if (m != null){
            return true;
        }else{
            return false;
        }
    }

    static validateText(text: string, type: string){
        switch (type) {
            case "email":
                return this.isValidEmail(text);
            default:
                break;
        }
    }

    static async getCountries(){
        return JSON.parse(countrys['response']);
    }

    static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static numberFieldKeyPress(event: KeyboardEvent){
        if(isNaN(parseInt(event.key))){
            event.preventDefault();
        }
    }

    static numberFieldInput(value: string){
        var n = parseInt(value);
        if(isNaN(n)){
            return false;
        }
        return true;
    }

    static async urltoFile(url, filename, mimeType){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename, {type:mimeType});})
        );
    }

    static createGUID(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line
            var random = Math.random() * 16 | 0, v = c == 'x' ? random : (random & 0x3 | 0x8);
            return random.toString(16);
        });
    }
}
