import { Router } from '@angular/router';
import { NetworkService } from './../network/_services/network';
import { DashboardLayoutComponent, DashboardValues } from './../dashboard-layout/dashboard-layout.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {

  parent: DashboardLayoutComponent;

  rewardsData: RewardsData = new RewardsData();

  rewards_data_async: boolean = true;
  errorPage:boolean = false;
  errorPage_text: string = "";
  showRetryOnError: boolean = false;
  dashboard_vars: DashboardValues = new DashboardValues();

  constructor(private network: NetworkService, private router: Router) { 

  }

  ngOnInit() {
    this.loadRewards();
  }

  loadRewards(){
    this.rewards_data_async = true;
    this.showRetryOnError = false;
    this.errorPage = false;
    this.network.sendRequest({}, NetworkService.GET_REWARDS).subscribe((res)=>{
      this.rewards_data_async = false;
      if(!res['error']){
        this.rewardsData = JSON.parse(res['response']);
      }else{
        this.errorPage = true;
        this.errorPage_text = res['response'];
      }
    }, (error)=>{
      this.errorPage = true;
      this.rewards_data_async = false;
      this.showRetryOnError = true;
      this.errorPage_text = "Sorry an unexpected error occured.";
    })
  }

  setDashBoardVars(vars){
    this.dashboard_vars = vars;
  }

  badgeMeterPercentage(){
    try {
      var rat = ((this.dashboard_vars.points_to_get_badge - this.dashboard_vars.points_left_to_badge)/this.dashboard_vars.points_to_get_badge) * 100
      if(isNaN(rat)){
        return "0%";
      }
      return rat + "%";
    } catch (error) {
      return "0%";
    }
  }

  navigate(route){
    this.router.navigate(['/'+ route]);
  }

}
class RewardsData{

  userRewards: Array<UserMonthReward> = new Array();
  leaderBoard: Array<LeaderBoardItem> = new Array();
}

class LeaderBoardItem{
  fullname: string = "";
  points: string = "";
  badges: string = "";
}

class UserMonthReward{
  points: string = "";
  treated_files: string = "";
  revenue: string = "";
  month: string = "";
}
