import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isFunction } from 'util';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public confirmMessage:string;

  ngOnInit() {
    // this.dialogRef.afterClosed().subscribe(()=>{
    //   if(this.data.buttons.length = 1 && this.data.buttons[0].text == "Ok"){
    //     this.data.buttons[0]
    //   }
    // })
    if(this.data.timeout){
      setTimeout(() => {
        this.dialogRef.close();
        try {
          this.data.timeoutCallBack();
        } catch (error) {
        }
        
      }, this.data.timeout);
    }
  }

  close(button){
    this.dialogRef.close();
    try {
      //if(isFunction(button.callback)){
        button.callback();
      //}
    } catch (error) {
    }
    
  }

}
