import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkService } from '../network/_services/network';
import { AlertHelper } from '../helpers/alert-helper';
import { User } from '../models/user';
import { DashboardLayoutComponent } from '../dashboard-layout/dashboard-layout.component';
import { Helpers } from '../helpers';
import moment from 'moment';
import { HttpEventType } from '@angular/common/http';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-view-archive',
  templateUrl: './view-archive.component.html',
  styleUrls: ['./view-archive.component.css']
})
export class ViewArchiveComponent implements OnInit {
  finishFinalize = false;
  errorPage: boolean;
  commentsAsync: boolean;
  finishSubmission = false;
  departmentsLoadAsync = false;
  departmentUsersLoadAync = false;
  beginRoute = false;
  errorPageText = 'Sorry an unexpected error occured';
  showRetryOnError: boolean;
  files_async: boolean = false;
  fileInfo = new FileInfo();
  current_doc:number = 1;
  currentSlide: SlidesOutputData;
  currentFile: {fid: string, file_name: string, url: string} = {fid: '', file_name: '', url: ''};
  file_ref = '';
  alertHelper: AlertHelper;
  loggedUser: User;
  username = '';

  toggleUsersDropdown = false;
  selectedRoutee = null;
  routeMessage = '';

  fileDepartments: {id: string, name: string}[] = new Array();
  departmentUsers: {id: string, name: string, PermissionLevel: number, position: string}[] = new Array();
  selectedDepartment: {id: string, name: string};

  commentMessage = '';
  commentsReload = false;

  @ViewChild('routeFiles', {static: false}) routeFiles: ElementRef;
  @ViewChild('finalizeFiles', {static: false}) finalizeFiles: ElementRef;
  selected_route_files: Array<File> = new Array();
  selected_finalize_files: Array<File> = new Array();

  maxFilesNo = 5;
  file_types_url = Helpers.file_types_urls;
  showAttachmentView = false;

  selectedComment: any;

  finalize = false;
  finalizeMessage = ''
  originalDocument = [];

  showHistory = false;


  parent: DashboardLayoutComponent;

  config = {
    displayKey: "name", // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '200px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // limitTo: 10, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No users found with matching document permissions level!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };



constructor(private network:NetworkService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) { 
  this.route.params.subscribe((params) => {
    console.log(params)
    this.file_ref = params['FilesTrackingNo'];
  })
  if(!this.file_ref) {
    this.router.navigate(['/']);
  }else{
    this.fileInfo.ref = '#' + this.file_ref;
    this.file_ref = this.fileInfo.ref;
  }
  this.alertHelper = new AlertHelper(dialog);
  this.loggedUser = User.getCurrentUser();
  this.username = this.loggedUser.FirstName + " " + this.loggedUser.LastName;
}

ngOnInit() {
  this.loadFileDetails();
}

slidePage(data: SlidesOutputData){
  this.currentSlide = data;
  if(data.slides.length > 0){
    this.currentFile = this.fileInfo.files.find((f)=>{
      return f.fid == data.slides[0].id;
    })
  }
}
noComments = false;
desk = '';
loadFileDetails() {
  this.files_async = true;
  this.network.sendRequest({trackingNo: this.fileInfo.ref}, NetworkService.GET_ARCH_FILE).subscribe((response) => {

    this.files_async = false;

    if (!response['error']){
      this.errorPage = false;
      // check the file permission level on 'archive and correspondence' typed documents and call a fn to handlle flagging
      this.fileInfo = JSON.parse(response['response']);
      console.log(this.fileInfo);
      this.fileInfo.files.forEach(element => {
        this.originalDocument.push(element);
      });
      console.log(this.originalDocument);
      this.fileInfo.ref = this.file_ref;
      if (this.fileInfo.comments.length > 0) {
        this.desk = this.fileInfo.comments.reverse()[this.fileInfo.comments.length - 1].recipient.split('-')[0].trim();
        this.noComments = false;
      } else {
        this.noComments = true;
      }
        //this.currentFile = this.fileInfo.file[0];
    }else{
      this.errorPageText = response['response'];
      this.errorPage = true;
    }
  }, (error)=>{
    this.files_async = false
    this.showRetryOnError = true;
    this.errorPageText = "Sorry, an unexpected error occured.";
    this.errorPage = true;
  });
}

refreshComments(){
  this.commentsReload = true;
  this.network.sendRequest({refNo: this.file_ref}, NetworkService.GET_ARCH_COMMENTS)
  .subscribe((res)=>{
    if(!res['error']){
      this.commentsReload = false;
      this.fileInfo.comments = JSON.parse(res['response']);
      this.fileInfo.comments = this.fileInfo.comments.reverse();
    }
  }, (error)=>{
    this.commentsReload = false;
    //return this.alertHelper.showOkAlert("""An error occured while reloading the entrie, please try later");
  })
}

currentArchiveFile = 0;

currentCommentFile = 0;
  prevArchiveDoc() {
    if (this.currentArchiveFile > 0) {
      this.currentArchiveFile -= 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }
  nextArchiveDoc() {
    if (this.currentArchiveFile < this.fileInfo.files.length - 1) {
      this.currentArchiveFile += 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }


docsLoaded(event){
  if(this.files_async){
    this.files_async = false;
  }
}

getFullDate(datestring: string){
  return this.parent.getFullDate(datestring);
}

loadDepartments(team?: boolean) {
  this.refreshDepartmentUsers();
  if(this.fileDepartments.length <= 0){
    this.departmentsLoadAsync = true;
    this.network.sendRequest({correspondenceNo: this.file_ref}, NetworkService.GET_ARFile_DEPARTMENTS)
    .subscribe((res) => {
      this.departmentsLoadAsync = false;
      if(!res['error']){
        const deps = JSON.parse(res['response']);
        this.fileDepartments = deps;
      }
    }, (error) => {
      this.departmentsLoadAsync = false;
    });
  }
}

routeComment() {
  this.beginRoute = true;
  this.loadDepartments();
}

fileDepartmentsChange(event) {
  this.refreshDepartmentUsers();
  this.selectedDepartment = event.value;
  if(event.value){
    this.departmentUsersLoadAync = true;
    this.network.sendRequest({correspondenceNo: this.file_ref, did: event.value.id}, NetworkService.GET_ARFile_DEPARTMENT_USERS)
    .subscribe((res) => {
      this.departmentUsersLoadAync = false;
      if(!res['error']){
        const usrs = JSON.parse(res['response']);
        this.departmentUsers = usrs;
      }
    }, (error) => {
      this.departmentUsersLoadAync = false;
    });
  }
}

refreshDepartmentUsers(){
  this.toggleUsersDropdown = false;
  this.selectedRoutee = null;
  this.departmentUsers = new Array();
  setTimeout(() => {
    this.toggleUsersDropdown = true;
  }, 1);
}

submitRoute(){
  if(this.selectedDepartment == null){
    this.alertHelper.showOkAlert('Error', 'Please select user\'s department')
  }else if(this.selectedRoutee.id == null){
    this.alertHelper.showOkAlert("Error", "Please select a user")
  }else if(this.routeMessage.trim() === '') {
    this.alertHelper.showOkAlert('Error', 'Please enter a message for user')
  }else{
    if(!this.fileInfo.isRoutable){
      this.alertHelper.showOkAlert("Access denied", 'This file is currently being treated on '+ this.desk + '\'s desk and is not available for routing, please check back later.')
    }else{
      this.completeSubmitRoute();
    }
  }
}
completeSubmitRoute(){
  this.finishSubmission = true;
  
  let data = {};
  data['recipientId'] = this.selectedRoutee.id;
  data['message'] = this.routeMessage;
  data['refNo'] = this.file_ref;
  data['files'] = this.selected_route_files;
  let url = NetworkService.ROUTE_ARCH;

  this.network.filesUploadKey(this.selected_route_files, url, data).subscribe((res)=>{
    this.handleTreatFileUploadProgress(res);
}, (error) =>{
  this.finishSubmission = false;
  this.alertHelper.showErrorAlert();
})

}

handleTreatFileUploadProgress(event){
  if (event.type === HttpEventType.Response) {
    var serverResponse = event.body
    if(!serverResponse['error']){
      let comment: FileComment = new FileComment();
      comment.sender = this.loggedUser.FirstName + " " + this.loggedUser.LastName + " (" + this.loggedUser.Position + ")";
      comment.recipient = this.selectedRoutee.name;
      let cdate = moment().toString();
      comment.commentDate = cdate
      comment.comment = this.routeMessage;
      comment.senderColor = this.loggedUser.UserColour;
      //this.fileInfo.comments.unshift(comment);
      this.refreshComments();
      this.fileInfo.isRoutable = false;
      this.fileInfo.isFinalizableByUser = false;
      this.desk = this.selectedRoutee.name.split("(")[0].trim();
      this.fileInfo.isFinalizableByAnotherUser = true;
      this.noComments = false;
      this.alertHelper.showOkAlert("Success", serverResponse['response']);
      this.closeRouteDialog();
      this.routeMessage = '';
      this.parent.reloadDashBoardVars();
      console.log(this.fileInfo.comments)
      
      this.finishSubmission = false;
      }else{
        this.alertHelper.showOkAlert("Error", serverResponse['response']);
        this.finishSubmission = false;
      }
  }
}

closeRouteDialog() {
  this.beginRoute = false;
  const routeFiles = this.routeFiles.nativeElement;
  routeFiles.value = '';
  this.selected_route_files = new Array();
}

closeFinalizeDialog() {
  this.finalize = false;
  const finalizeFiles = this.finalizeFiles.nativeElement;
  finalizeFiles.value = '';
  this.selected_finalize_files = new Array();
}

change() {
  var routeFiles = this.routeFiles.nativeElement;
  var fileError: boolean = false;
  if(routeFiles.files){
    var total_file_count = this.selected_route_files.length + routeFiles.files.length;
    if(total_file_count <= this.maxFilesNo){
      for(var i = 0; i < routeFiles.files.length; i++){
        var findFile = this.selected_route_files.find((f)=>{
          return f.name == routeFiles.files[i].name;
        });
        if (findFile) {
          return this.alertHelper.showOkAlert("Skipped files", "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format");
          return;
        }

        if(this.file_types_url[routeFiles.files[i].type] && !findFile){
          this.selected_route_files.push(routeFiles.files[i]);
        }else{
          fileError = true;
        }
      }
      if(fileError){
        this.alertHelper.showOkAlert("Skipped files", "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format")
      }
    }else{
      this.alertHelper.showOkAlert("Skipped files", "You have reached the maximum number of files")
    }
  }else{
    // this.selected_finalize_files = new Array(); 
  }
}

browse() {
  const input: HTMLInputElement = this.routeFiles.nativeElement;
  input.click();
}

remove() {
  const routeFiles = this.routeFiles.nativeElement;
  routeFiles.value = '';
  this.selected_route_files = new Array();
}

changeFinalizeFile() {
  var finalizeF = this.finalizeFiles.nativeElement;
  var fileError: boolean = false;
  if(finalizeF.files){
    var total_file_count = this.selected_finalize_files.length + finalizeF.files.length;
    if(total_file_count <= this.maxFilesNo - 1){
      for(var i = 0; i < finalizeF.files.length; i++){
        var findFile = this.selected_finalize_files.find((f)=>{
          debugger;
          return f.name === finalizeF.files[i].name;
        });
        if (findFile) {
          this.alertHelper.showOkAlert("Skipped files", "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format");
          return;
        }

        if(this.file_types_url[finalizeF.files[i].type] && !findFile){
          this.selected_finalize_files.push(finalizeF.files[i]);
        }else{
          return this.alertHelper.showOkAlert("Skipped files", "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format")
        }
      }
      if(fileError){
        this.alertHelper.showOkAlert("Skipped files", "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format")
      }
    }else{
      this.alertHelper.showOkAlert("Skipped files", "You have reached the maximum number of files")
    }
  }else{
    // this.selected_finalize_files = new Array(); 
  }
}

browseFinalizeFile() {
  const input: HTMLInputElement = this.finalizeFiles.nativeElement;
  input.click();
}

removeFinalizeFile() {
  const finalizeFile = this.finalizeFiles.nativeElement;
  finalizeFile.value = '';
  this.selected_finalize_files = new Array();
}


beginFinalize() {
  if(this.finalizeMessage.trim() === '') {
   this.alertHelper.showOkAlert('Error', 'Please enter a response for the sender');
 }else{
   if(this.selected_finalize_files.length <= 0){
     this.alertHelper.showYesNoAlert("Confirm", "Are you sure you want to finalize this correspondence without files?", () => {this.finalizeDocument()})
     return;
   }else{
     this.finalizeDocument();
   }
 }
}


showAttachments(comment: FileComment) {
  this.selectedComment = comment;
  this.parent.hideScroll();
  this.showAttachmentView = true;
}

closeAttachments() {
  this.parent.showScroll();
  this.showAttachmentView = false;
}


finalizeDocument() {
  this.finishFinalize = true;
  let data = {};
  data['recipientId'] = this.fileInfo.comments[this.fileInfo.comments.length - 1].lsId;
  data['message'] = this.finalizeMessage;
  data['refNo'] = this.file_ref;
  data['originalDocument'] = JSON.stringify( this.originalDocument);
  data['files'] = this.selected_finalize_files;
  let url = NetworkService.FINALIZE_ARCH;

  this.network.filesUploadKey(this.selected_finalize_files, url, data).subscribe((res)=>{
    this.FinalizeUploadProgress(res);
}, (error) =>{
  this.finishFinalize = false;
  this.alertHelper.showErrorAlert();
})
  
}

FinalizeUploadProgress(event){
  if (event.type === HttpEventType.Response) {
    var serverResponse = event.body
    if(!serverResponse['error']){
      this.closeFinalizeDialog();
      this.alertHelper.showOkAlert("Success!", "Finalized correspondence successfully!");
      this.finishFinalize = false;
      this.loadFileDetails();
      this.refreshComments();
      }else{
        this.finishFinalize = false;
        this.alertHelper.showOkAlert("Error", serverResponse['response']);
      }
  }
}

}
class FileInfo {
  ref = '';
  Sender = ''; // name, position
  UploadedBy = '';
  DateOnDocument = '';
  UploadedOn = '';
  Title = '';
  AddressedTo = '';
  Recipients: Array<{email: string, Department: string, Position: string}> = new Array();
  Department = '';
  DocumentType = '';
  files: Array<{fid: string, file_name: string, url: string}> = new Array();
  level = '';
  comments: Array<FileComment> = new Array();
  isFinalizableByUser: boolean;
  isFinalizableByAnotherUser: boolean;
  isRoutable: boolean
  }

export class FileComment{
  commentId = '';
  sender = '';
  recipient = '';
  comment = '';
  commentDate = '';
  senderColor = '';
  files: Attachment[];
  isFinalizeComment: boolean;
  lsId: number;
}

class Attachment {
  fid = '';
  url = '';
}

class finalizeAttachment {
  fid = '';
  url = '';
  name = '';
}


