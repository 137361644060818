import { Component, OnInit, ViewChild } from '@angular/core';
import { InterMinistryService } from 'src/app/_services/inter-ministry.service';
import { InterMinistrySubmissions } from 'src/app/models/inter-ministry-submissions';
import { PageSettingsModel, GridComponent } from '@syncfusion/ej2-angular-grids';
import { ChangeEventArgs } from '@syncfusion/ej2-inputs';
import { PaginatedResult } from 'src/app/helpers/Pagination';
import { PagerComponent } from '@syncfusion/ej2-angular-grids';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  PendingSubsList: InterMinistrySubmissions[];
  TreatedSubsList: InterMinistrySubmissions[];
  UntreatedSubsList: InterMinistrySubmissions[];
  pageSettingsTreated: PageSettingsModel;
  pageSettingsUntreated: PageSettingsModel;
  pageSettingsPending: PageSettingsModel;
  requestedPage = 1;
  requesting = false;
  UntreatedErrorDiv = false;
  TreatedErrorDiv = false;
  PendingErrorDiv = false;
  loadingAll = false;
  untreatedToday = 0;
  treatedToday = 0
  pendingToday = 0;

  @ViewChild("pagerTemplate", {static: false}) pagerTemplate: PagerComponent;

  constructor(private interMinistry: InterMinistryService) { 
  }

  ngOnInit() {
    this.pageSettingsUntreated = { pageSize:10, currentPage: 1 };
    this.pageSettingsTreated = { pageSize:10, currentPage: 1 };
    this.pageSettingsPending = { pageSize:10, currentPage: 1 };
    this.loadAllStats();
  }

  loadAllStats() {
    // this.loadingAll = true;
    // this.getUntreatedStats();
    // this.getPendingStats();
    // this.getTreatedStats();
    // this.loadingAll = false;
    this.getUntreatedStats();
  }

  getUntreatedStats() {
    this.requesting = true;

    this.interMinistry.getStats('untreated', this.pageSettingsUntreated.currentPage, this.pageSettingsUntreated.pageSize).subscribe((response) => {
      if (!response['error']) {
        const paginatedResult: PaginatedResult<InterMinistrySubmissions[]> = new PaginatedResult<InterMinistrySubmissions[]>();
        paginatedResult.result = JSON.parse(response.body['response']);
        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        this.pageSettingsUntreated.pageCount = paginatedResult.pagination.totalPages > 0 ? paginatedResult.pagination.totalPages : 1;
        this.pageSettingsUntreated.pageSize = paginatedResult.pagination.itemsPerPage;
        this.pageSettingsUntreated.totalRecordsCount = paginatedResult.pagination.totalItems > 0 ? paginatedResult.pagination.totalItems : 0;
        this.pageSettingsUntreated.currentPage = paginatedResult.pagination.currentPage;
        this.UntreatedSubsList = paginatedResult.result;
        this.UntreatedSubsList.forEach((val) => {
          val.CreatedAt = this.interMinistry.tranformDate(val.CreatedAt);
          // if(this.interMinistry.isToday(val.CreatedAt)) {
          //   this.untreatedToday += 1;
          // }
        })
        this.requesting = false;
      }
    }, error => {
      this.requesting = false;
      this.UntreatedErrorDiv = true;
      console.log('error!');
    })
    
  }

  getTreatedStats() {
    this.requesting = true;
    this.interMinistry.getStats('treated', this.pageSettingsTreated.currentPage, this.pageSettingsTreated.pageSize).subscribe((response) => {
      if (!response['error']) {
        const paginatedResult: PaginatedResult<InterMinistrySubmissions[]> = new PaginatedResult<InterMinistrySubmissions[]>();
        paginatedResult.result = JSON.parse(response.body['response']);
        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        this.pageSettingsTreated.pageCount = paginatedResult.pagination.totalPages > 0 ? paginatedResult.pagination.totalPages : 1;
        this.pageSettingsTreated.pageSize = paginatedResult.pagination.itemsPerPage;
        this.pageSettingsTreated.totalRecordsCount = paginatedResult.pagination.totalItems > 0 ? paginatedResult.pagination.totalItems : 0;
        this.pageSettingsTreated.currentPage = paginatedResult.pagination.currentPage;
        this.TreatedSubsList = paginatedResult.result;
        this.TreatedSubsList.forEach((val) => {
          val.CreatedAt = this.interMinistry.tranformDate(val.CreatedAt);
          // if(this.interMinistry.isToday(val.CreatedAt)) {
          //   this.pendingToday += 1;
          // }
        })
        this.requesting = false;
      }
      console.log(this.TreatedSubsList);
    }, error => {
      console.log('error!');
      this.requesting = false;
      this.PendingErrorDiv = true;
    })
    
  }

  getPendingStats() {
    this.requesting = true;
    this.interMinistry.getStats('pending', this.pageSettingsPending.currentPage, this.pageSettingsPending.pageSize).subscribe((response) => {
      if (!response['error']) {
        const paginatedResult: PaginatedResult<InterMinistrySubmissions[]> = new PaginatedResult<InterMinistrySubmissions[]>();
        paginatedResult.result = JSON.parse(response.body['response']);
        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        this.pageSettingsPending.pageCount = paginatedResult.pagination.totalPages > 0 ? paginatedResult.pagination.totalPages : 1;
        this.pageSettingsPending.pageSize = paginatedResult.pagination.itemsPerPage;
        this.pageSettingsPending.totalRecordsCount = paginatedResult.pagination.totalItems > 0 ? paginatedResult.pagination.totalItems : 0;
        this.pageSettingsPending.currentPage = paginatedResult.pagination.currentPage;
        this.PendingSubsList = paginatedResult.result;
        this.PendingSubsList.forEach((val) => {
          val.CreatedAt = this.interMinistry.tranformDate(val.CreatedAt);
          // if(this.interMinistry.isToday(val.CreatedAt)) {
          //   this.treatedToday += 1;
          // }
        })
        this.requesting = false;
      }
      console.log(this.PendingSubsList);
    }, error => {
      console.log('error!');
      this.requesting = false;
      this.TreatedErrorDiv = true;
    })
    
  }

  UntreatedPageChange(event?) {
    if(event) {
      this.pageSettingsUntreated.currentPage = event.pageIndex + 1;
      this.getUntreatedStats();
    }
  }
  TreatedPageChange(event?) {
    if(event) {
      this.pageSettingsTreated.currentPage = event.pageIndex + 1;
      this.getTreatedStats();
    }
  }
 PendingPageChange(event?) {
    if(event) {
      this.pageSettingsPending.currentPage = event.pageIndex + 1;
      this.getPendingStats();
    }
  }

  loadPendingList() {
    if(this.PendingSubsList) {
      return;
    }
    this.getPendingStats();
  }

  loadTreatedList() {
    if(this.TreatedSubsList) {
      return;
    }
    this.getTreatedStats();
  }

}
