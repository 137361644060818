import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinistryCorrespondenceComponent } from './ministry-correspondence/ministry-correspondence.component';
import { RouterModule } from '@angular/router';
import { GuardService } from '../network/_guards/auth.guard';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { StatsComponent } from './ministry-correspondence/stats/stats.component';
import { ViewSubmissionComponent } from './ministry-correspondence/view-submission/view-submission.component';


@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        RouterModule.forChild([
            {
                path: 'ministry-correspondence', 
                canActivate: [GuardService], 
                component: MinistryCorrespondenceComponent,  
                data:{title: 'Ministry Correspondence'},
                
                children: [
                    { path: 'view-submission/:refNo', component: ViewSubmissionComponent, data: {title: 'View Submission'} }
                ]
            }
        ])
    ],
    declarations: [
        MinistryCorrespondenceComponent,
        StatsComponent,
        ViewSubmissionComponent
    ],
    exports: [
        MinistryCorrespondenceComponent, CommonModule,
        ViewSubmissionComponent
    ],
    providers: [

    ]
})
export class InterMinistryModule {

}