import { Component, OnInit, ViewChild } from '@angular/core';
import { NetworkService } from '../network/_services/network';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertHelper } from '../helpers/alert-helper';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-document-search',
  templateUrl: './document-search.component.html',
  styleUrls: ['./document-search.component.css']
})
export class DocumentSearchComponent implements OnInit {
queryString = '';
alertHelper: AlertHelper;
searchLoader = false;
searchGridLoader = false;
document: any;
minutes_loader: any;
@ViewChild('memogrid', {static: false}) public searchGrid: GridComponent;

constructor(private network: NetworkService, private router: Router, private dialog: MatDialog) {
  this.alertHelper = new AlertHelper(dialog);
 }

  ngOnInit() {
  }

  search() {
    if (this.queryString) {
      this.searchLoader = true;
      this.network.sendRequest(this.queryString, NetworkService.GET_INT_DOC_LIST).subscribe((res) => {
        if (!res['error']) {

        }
      });
    }
  }

  rowDblClickOpenDocument(event?) {
    
  }
}
