import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-opening-route-comments',
  templateUrl: './account-opening-route-comments.component.html',
  styleUrls: ['./account-opening-route-comments.component.scss']
})
export class AccountOpeningRouteCommentsComponent implements OnInit {
  @Input() choiceElementId
  @Input() comments = []

  constructor() { }

  ngOnInit() {
  }

}
