import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
  @Input() url
  @Input() elementId
  @Input() fileName

  @ViewChild('viewer', {static: false}) viewer;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  sanitize(url) {
    if(url) return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  returnUrl(url: string) {
    if(url)
    return url.toString()
  }

  refreshViewer() {
    this.viewer.refresh()
  }

}
