import { AuthService } from './../network/_services/auth.service';
import { Router } from '@angular/router';
import { NetworkService } from './../network/_services/network';
import { AlertHelper } from './../helpers/alert-helper';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Helpers } from '../helpers';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  tokenExpired: boolean = false;
  async_loader: boolean = false;
  token_email: {token: string, email:string} = {token: "", email: ""};
  alertHelper: AlertHelper;
  constructor(private dialog: MatDialog, private auth: AuthService, private router: Router) { 
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    var stored_email = localStorage.getItem(Helpers.VERIFY_EMAIL_DETAIL);
    if(stored_email){
      var token_email = JSON.parse(stored_email);
      this.token_email.email = token_email.email;
      this.tokenExpired = token_email.tokenExpired;
      localStorage.removeItem(Helpers.VERIFY_EMAIL_DETAIL);
    }
  }


  resendToken(){
    this.auth.resendToken({email: this.token_email.email}).subscribe((res)=>{
      if(!res['error']){
        this.tokenExpired = false;
      }else{
        this.async_loader = false;
        this.alertHelper.showOkAlert("Error", res['response']);
      }
    }, (error)=>{
      this.async_loader = false;
      this.alertHelper.showErrorAlert();
    });
  }

  verifyToken(){
    if(this.token_email.token.toString().trim() == ""){
      this.alertHelper.showOkAlert("No token", "Please enter the token that was sent to your email address");
    }else{
      this.async_loader = true
      this.auth.activateAccount(this.token_email).subscribe((res)=>{
        if(!res['error']){
          this.router.navigate(['/register/done']);
        }else{
          this.async_loader = false;
          var token_email = null;
          if(res['response'] == Helpers.ERR_TOKEN_EXPIRED){
            this.tokenExpired = true;
          }else{
            this.alertHelper.showOkAlert("Error", res['response']);
          }
        }
      }, (error)=>{
        this.async_loader = false;
        this.alertHelper.showErrorAlert();
      });
    }
  }

}
