import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up-done',
  templateUrl: './sign-up-done.component.html',
  styleUrls: ['./sign-up-done.component.scss']
})
export class SignUpDoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
